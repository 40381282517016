/* eslint-disable react/no-unused-state */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-nested-ternary */
/* eslint-disable class-methods-use-this */
import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// @material-ui/Icons
import AccessTime from '@material-ui/icons/AccessTime';
import Edit from '@material-ui/icons/Edit';
import DoneAll from '@material-ui/icons/DoneAll';
import ArrowForward from '@material-ui/icons/ArrowForward';
import Done from '@material-ui/icons/Done';
import Cached from '@material-ui/icons/Cached';
// import NotificationImportant from '@material-ui/icons/NotificationImportant';
import Block from '@material-ui/icons/Block';
import CircularProgress from '@material-ui/core/CircularProgress';
import purple from '@material-ui/core/colors/purple';
import Report from '@material-ui/icons/Report';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

// core components
import Table from 'components/Table/Table.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Card from 'components/Card/Card.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
// @material-ui/icons
import Close from '@material-ui/icons/Close';
import { green, amber, red } from '@material-ui/core/colors';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TransactionStyle from 'assets/jss/material-dashboard-pro-react/views/TransactionStyle';
import Responsive from 'react-responsive';
import moment from 'moment';
import {
  switchActivePage,
  setCurrentTransaction,
  setCurrentTransactionUnsubscribe,
  setTransactions,
  switchModifyPage,
} from '../../../reducers/transactions';
import firebase from '../../../config/config';
import history from '../../../history';
import '../../../assets/scss/transactionCardStyles.scss';
import auditLogger from '../../../utils/auditLoggerToPG';
import mapRole from '../../../utils/roleMapping';

const Desktop = props => <Responsive {...props} minWidth={701} />;
const Mobile = props => <Responsive {...props} maxWidth={700} />;

// Fireabse
const db = firebase.firestore();

const GreenRadio = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
    marginTop: '-10px',
  },
  checked: {},
})(props => <Radio color="default" {...props} />);

const AmberRadio = withStyles({
  root: {
    color: amber[400],
    '&$checked': {
      color: amber[600],
    },
    marginTop: '-10px',
  },
  checked: {},
})(props => <Radio color="default" {...props} />);

const RedRadio = withStyles({
  root: {
    color: red[400],
    '&$checked': {
      color: red[600],
    },
    marginTop: '-10px',
  },
  checked: {},
})(props => <Radio color="default" {...props} />);
const auditLog = firebase.functions().httpsCallable('utilities-auditLog');
class TransactionsCard extends React.Component {
  constructor(props) {
    // console.log('HISTORY STATE CHECK IN LIST VIEW', history.location);
    super(props);
    this.state = {
      transaction: [],
      checksAlert: false,
      checkOne: false,
      checkTwo: false,
      checksError: '',
      checksLoading: false,
      recentTransactionsLoading: false,
      recentTransactions: [],
      truckerContactPerson: '',
      transporterContactPerson: '',
      activeBankDetails: {},
      activeBankDetailsLoading: false,
    };
    this.showDetailedTransaction = this.showDetailedTransaction.bind(this);
    this.showModifyPage = this.showModifyPage.bind(this);
    this.handleToggle = this.handleToggle.bind(this);
    this.handleCheckOne = this.handleCheckOne.bind(this);
    this.handleCheckTwo = this.handleCheckTwo.bind(this);
    this.closeChecksAlert = this.closeChecksAlert.bind(this);
    this.showChecksAlert = this.showChecksAlert.bind(this);
    this.finishChecks = this.finishChecks.bind(this);
    this.getTableHeadings = this.getTableHeadings.bind(this);
    this.showTransactions = this.showTransactions.bind(this);
  }

  componentDidMount() {
    // console.log('called on initialization', history.location);

    const selectedBranchAsParams = history.location.state;
    const {
      maintainSelectedBranch,
      activeRole,
      systemDetails,
      userDetails,
    } = this.props;
    if (activeRole === 'branch-ops') {
      maintainSelectedBranch(selectedBranchAsParams);
    }
    const role = mapRole(activeRole);

    const auditPayload = {
      collection: 'Transactions',
      orgId: null,
      data: {},
      message: `${userDetails.email} viewed list transactions`,
      systemDetails: {},
      type: 'List View Transaction',
      role: `${this.capitalizeFirstLetter(role)}`,
      eventDateTime: new Date().toISOString(),
      source: 'office',
    };
    auditLogger(auditPayload);
  }

  getMobileCard(transaction) {
    const { classes, sales, approved, userDetails } = this.props;
    let color = '#fff';
    if (transaction.isIncorrectPayable) {
      color = '#f13b3bc7';
    } else if (
      transaction.status === 'approved' &&
      (!transaction.checkOne || !transaction.checkTwo)
    ) {
      color = '#d9edf7';
    } else if (
      transaction.status === 'approved' &&
      transaction.checkOne &&
      transaction.checkTwo
    ) {
      color = '#dff0d8';
    } else if (transaction.status === 'pending') {
      color = '#f3e2c9';
    } else if (transaction.status === 'modify') {
      color = '#fcf8e3';
    } else if (transaction.status === 'rejected') {
      color = '#f2dede';
    }
    const { isSubmitted, isCollected } = transaction;
    // console.log('Props', this.props.selectedBranch);
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <Card
            chart
            className={classes.card}
            style={{ backgroundColor: color }}
          >
            <CardBody>
              <GridContainer>
                <GridItem xs={3} sm={12} md={12} lg={12}>
                  <p
                    style={{
                      fontWeight: 'bold',
                      fontSize: '15px',
                      marginBottom: '3px',
                      float: 'left',
                      textTransform: 'uppercase',
                    }}
                  >
                    {transaction.fromCity.substring(0, 3)}{' '}
                  </p>
                </GridItem>
                <GridItem xs={6} sm={12} md={12} lg={12}>
                  <p
                    style={{
                      fontWeight: 'bold',
                      fontSize: '15px',
                      marginBottom: '3px',
                      float: 'center',
                    }}
                  >
                    <span style={{ paddingLeft: '25px' }}>
                      <Button
                        size="sm"
                        simple
                        style={{
                          color: '#000',
                          fontSize: '14px',
                          padding: '0.0625rem 0.25rem',
                          marginTop: '-2px',
                          fontWeight: 'bold',
                        }}
                        onClick={() =>
                          this.showDetailedTransaction(transaction)
                        }
                      >
                        #{transaction.transactionSerial}
                      </Button>
                    </span>
                  </p>
                </GridItem>
                <GridItem xs={3} sm={12} md={12} lg={12}>
                  <p
                    style={{
                      fontWeight: 'bold',
                      fontSize: '15px',
                      marginBottom: '3px',
                      float: 'right',
                      textTransform: 'uppercase',
                    }}
                  >
                    {transaction.toCity.substring(0, 3)}
                  </p>
                </GridItem>
                <GridItem xs={12}>
                  <p style={{ fontSize: '12px', float: 'left' }}>
                    {transaction.transporterName}
                  </p>
                  <p style={{ fontSize: '12px', float: 'right' }}>
                    {' '}
                    {transaction.truckerName}
                  </p>
                </GridItem>
                <GridItem xs={12}>
                  <p style={{ fontSize: '12px', float: 'left' }}>
                    Commission :<i className="fa fa-inr" aria-hidden="true" />{' '}
                    {Intl.NumberFormat('en-IN').format(transaction.commission)}
                  </p>
                  <p
                    style={{
                      fontWeight: 'bold',
                      fontSize: '15px',
                      marginBottom: '3px',
                      float: 'right',
                    }}
                  >
                    {' '}
                    <i className="fa fa-inr" aria-hidden="true" />
                    <span>
                      {' '}
                      {Intl.NumberFormat('en-IN').format(transaction.amount)}
                    </span>
                  </p>
                </GridItem>
              </GridContainer>
              <GridItem>
                {transaction.status === 'modify' && sales ? (
                  <Button
                    size="sm"
                    color="info"
                    simple
                    onClick={() => this.showModifyPage(transaction)}
                  >
                    <Edit className={classes.underChartIcons} />
                    Modify
                  </Button>
                ) : (
                  <div>
                    {sales &&
                      approved &&
                      !(
                        (userDetails.isAdmin || userDetails.isOps) &&
                        transaction.branch === 'Premium'
                      ) && (
                        <Button
                          size="sm"
                          simple
                          color="warning"
                          onClick={() => this.showModifyPage(transaction)}
                        >
                          <Edit className={classes.underChartIcons} />
                          Edit
                        </Button>
                      )}
                  </div>
                )}
              </GridItem>
            </CardBody>
            <CardFooter chart>
              <div className={classes.stats}>{transaction.branch}</div>
              {isSubmitted && isCollected ? (
                <DoneAll
                  style={{
                    color: '#43a047 ',
                    alignItems: 'center',
                    float: 'left',
                    justifyContent: 'start',
                  }}
                />
              ) : isCollected ? (
                <Done
                  style={{
                    color: '#163BAC ',
                    alignItems: 'center',
                    float: 'left',
                    justifyContent: 'start',
                  }}
                />
              ) : null}
              {transaction.status === 'pending' && (
                <Report style={{ color: ' #ffa726 ', alignItems: 'center' }} />
              )}
              {transaction.status === 'modify' && (
                <Cached style={{ color: ' #2cd1e6 ', alignItems: 'center' }} />
              )}
              {transaction.status === 'rejected' && (
                <Block style={{ color: '#ef5350 ', alignItems: 'center' }} />
              )}
              <div className={classes.stats}>
                <AccessTime /> {transaction.loadingDate}
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }

  getDesktopCard(transaction) {
    let color = '#fff';
    let { riskStatus } = transaction;
    if (riskStatus === undefined) riskStatus = 'Green';
    if (transaction.isIncorrectPayable) {
      color = '#f13b3bc7';
    } else if (
      transaction.status === 'approved' &&
      (!transaction.checkOne || !transaction.checkTwo)
    ) {
      color = '#d9edf7';
    } else if (
      transaction.status === 'approved' &&
      transaction.checkOne &&
      transaction.checkTwo
    ) {
      color = '#dff0d8';
    } else if (transaction.status === 'pending') {
      color = '#f3e2c9';
    } else if (transaction.status === 'modify') {
      color = '#fcf8e3';
    } else if (transaction.status === 'rejected') {
      color = '#f2dede';
    }
    const {
      classes,
      sales,
      approved,
      finance,
      userDetails,
      activeRole,
    } = this.props;
    const { isSubmitted, isCollected } = transaction;
    const isTrackingRole = activeRole === 'tracking-role';
    return (
      <GridContainer
        justify="center"
        style={{ justify: 'center', alignItems: 'center' }}
      >
        <GridItem
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{ padding: '0px !important' }}
        >
          <Card
            chart
            className={classes.card}
            style={{
              marginTop: '1px',
              paddingTop: '5px',
              paddingBottom: '1px',
              backgroundColor: color,
              marginBottom: '1px',
            }}
          >
            <CardBody style={{ padding: '0.0375rem 10px' }}>
              <div className={transaction.void ? 'handle' : ''}>
                <div className={transaction.void ? 'sword red strike' : ''}>
                  <GridContainer className={classes.gridBottom}>
                    <GridItem xs={12} sm={9} md={9} lg={9}>
                      <GridContainer>
                        <GridItem xs={12} sm={1} md={1} lg={1}>
                          <Button
                            size="sm"
                            simple
                            style={{
                              color: '#000',
                              fontSize: '14px',
                              padding: '0.0625rem 0.35rem',
                              marginRight: '10px',
                              marginTop: '-2px',
                            }}
                            onClick={() =>
                              this.showDetailedTransaction(transaction)
                            }
                          >
                            #{transaction.transactionSerial}
                          </Button>
                        </GridItem>
                        <GridItem xs={12} sm={6} md={6} lg={6}>
                          <GridContainer>
                            <GridItem
                              xs={12}
                              sm={6}
                              md={6}
                              lg={6}
                              className={classes.cityPadding}
                            >
                              <p
                                style={{
                                  // fontWeight: 'bold',
                                  fontSize: '14px',
                                  marginBottom: '0px',

                                  float: 'left',
                                }}
                              >
                                <span>
                                  {' '}
                                  {transaction.fromCity.substring(0, 8)}{' '}
                                </span>
                                {' - '}
                                <span>
                                  {' '}
                                  {transaction.toCity.substring(0, 8)}
                                </span>
                              </p>
                            </GridItem>
                            <GridItem
                              xs={4}
                              sm={2}
                              md={2}
                              lg={2}
                              className={classes.noPadding}
                            >
                              {riskStatus === 'red' ? (
                                <RedRadio
                                  checked
                                  name="radio-button-demo"
                                  style={{ float: 'left' }}
                                />
                              ) : riskStatus === 'amber' ? (
                                <AmberRadio
                                  checked
                                  style={{ float: 'left' }}
                                  name="radio-button-demo1"
                                />
                              ) : (
                                <GreenRadio
                                  checked
                                  name="radio-button-demo"
                                  style={{ float: 'left' }}
                                />
                              )}
                            </GridItem>
                            <GridItem xs={4} sm={4} md={4} lg={4}>
                              <p
                                style={{
                                  fontSize: '14px',
                                  marginBottom: '0px',
                                  float: 'left',
                                  textDecoration: transaction.void
                                    ? 'line-through'
                                    : 'none',
                                }}
                              >
                                {isTrackingRole
                                  ? '****'
                                  : transaction.transporterName.substring(
                                      0,
                                      13
                                    )}
                              </p>
                            </GridItem>
                          </GridContainer>
                        </GridItem>

                        <GridItem xs={12} sm={2} md={2} lg={2}>
                          <p
                            style={{
                              fontSize: '14px',
                              marginBottom: '0px',
                              float: 'left',
                            }}
                          >
                            {transaction.truckerName.substring(0, 13)}
                          </p>
                        </GridItem>
                        <GridItem
                          xs={12}
                          sm={1}
                          md={1}
                          lg={1}
                          className={classes.noPadding}
                        >
                          <p
                            style={{
                              fontSize: '14px',
                              marginBottom: '0px',
                              float: 'right',
                              textDecoration: transaction.void
                                ? 'line-through'
                                : 'none',
                            }}
                          >
                            {isTrackingRole
                              ? '****'
                              : Intl.NumberFormat('en-IN').format(
                                  transaction.commission
                                )}
                          </p>
                        </GridItem>
                        <GridItem xs={12} sm={2} md={2} lg={2}>
                          <p
                            style={{
                              // fontWeight: 'bold',
                              fontSize: '14px',
                              marginBottom: '0px',
                              float: 'right',
                            }}
                          >
                            <span>
                              {isTrackingRole
                                ? '****'
                                : Intl.NumberFormat('en-IN').format(
                                    transaction.amount
                                  )}
                            </span>
                          </p>
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                    <GridItem
                      xs={12}
                      sm={3}
                      md={3}
                      lg={3}
                      className={classes.paddingLeftBranch}
                    >
                      <GridContainer style={{ margin: '0px' }}>
                        <GridItem
                          xs={12}
                          sm={3}
                          md={3}
                          lg={3}
                          // style={{ marginLeft: '5px' }}
                          className={classes.paddingLeftBranch}
                        >
                          <p
                            style={{
                              fontSize: '14px',
                              marginBottom: '0px',
                              float: 'left',
                            }}
                          >
                            {transaction.branch !== undefined
                              ? transaction.branch.substring(0, 3)
                              : null}
                          </p>
                        </GridItem>
                        <GridItem xs={12} sm={5} md={5} lg={5}>
                          <p
                            style={{
                              fontSize: '12px',
                              marginBottom: '0px',
                              float: 'left',
                            }}
                          >
                            {/* {moment(transaction.createdAt.toDate()).format(
                              'MMM Do Y'
                      )} */}
                            {transaction.loadingDate}
                          </p>
                        </GridItem>
                        <GridItem xs={12} sm={4} md={4} lg={4}>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'flex-end',
                            }}
                          >
                            <div>
                              {isSubmitted && isCollected ? (
                                <DoneAll
                                  style={{
                                    color: '#43a047 ',
                                    alignItems: 'center',
                                    float: 'left',
                                    justifyContent: 'start',
                                  }}
                                />
                              ) : isCollected ? (
                                <Done
                                  style={{
                                    color: '#163BAC ',
                                    alignItems: 'center',
                                    float: 'left',
                                    justifyContent: 'start',
                                  }}
                                />
                              ) : null}
                            </div>
                            <div
                              style={{ float: 'right', justifyContent: 'end' }}
                            >
                              <div
                                style={{
                                  float: 'right',
                                  justifyContent: 'end',
                                }}
                              >
                                {/* Check if display modify button */
                                transaction.status === 'modify' && sales ? (
                                  <Button
                                    size="sm"
                                    simple
                                    color="info"
                                    style={{
                                      padding: '0.0625rem 0.65rem',
                                      float: 'right',
                                    }}
                                    onClick={() =>
                                      this.showModifyPage(transaction)
                                    }
                                  >
                                    <Edit className={classes.underChartIcons} />
                                    {/* Modify */}
                                  </Button>
                                ) : (
                                  <React.Fragment>
                                    {/* Check if display Edit Button. Do not allow Ops or Admin to Edit */
                                    sales &&
                                      approved &&
                                      !(
                                        (userDetails.isAdmin ||
                                          userDetails.isOps) &&
                                        transaction.branch === 'Premium'
                                      ) && (
                                        <Button
                                          size="sm"
                                          color="warning"
                                          simple
                                          style={{
                                            padding: '0.0625rem 0.65rem',
                                          }}
                                          onClick={() =>
                                            this.showModifyPage(transaction)
                                          }
                                        >
                                          <Edit
                                            className={classes.underChartIcons}
                                          />
                                        </Button>
                                      )}
                                  </React.Fragment>
                                )}
                                {finance ? (
                                  transaction.checkOne &&
                                  transaction.checkTwo ? null : (
                                    <div>
                                      {activeRole === 'finance-payable' ||
                                      activeRole === 'finance-recievable' ||
                                      activeRole === 'finance-recon' ||
                                      activeRole === 'transaction-view' ||
                                      activeRole === 'tracking-role' ? null : (
                                        <Button
                                          color="warning"
                                          simple
                                          size="sm"
                                          className={
                                            classes.updateProfileButton
                                          }
                                          onClick={() =>
                                            this.showChecksAlert(transaction)
                                          }
                                          disabled={transaction.void}
                                        >
                                          <ArrowForward />
                                        </Button>
                                      )}
                                    </div>
                                  )
                                ) : null}

                                {!finance && (
                                  <div className={classes.stats}>
                                    {' '}
                                    {transaction.isEditedTransaction === true
                                      ? ' Edited'
                                      : null}{' '}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                </div>
              </div>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }

  getTableHeadings() {
    const { classes } = this.props;
    return (
      // <Grid xs={12}>
      //   <Grid Item>
      //     <p
      //       style={{
      //         fontWeight: 'bold',
      //         fontSize: '12px',
      //         marginBottom: '0px',
      //         float: 'right',
      //         color: '#0000007d',
      //       }}
      //     >
      //       No
      //     </p>
      //   </Grid>
      // </Grid>

      <GridItem xs={12} sm={12} md={12} lg={12}>
        <div style={{ marginTop: '10px' }}>
          <GridContainer
            justify="center"
            // style={{ justify: 'center', alignItems: 'center' }}
          >
            <GridItem
              xs={12}
              sm={12}
              md={12}
              lg={12}
              style={{ padding: '0px !important' }}
            >
              <GridContainer>
                <GridItem xs={12} sm={9} md={9} lg={9}>
                  <GridContainer>
                    <GridItem
                      xs={12}
                      sm={1}
                      md={1}
                      lg={1}
                      // className={classes.tableHeaderPadding}
                    >
                      <p
                        style={{
                          fontWeight: 'bold',
                          fontSize: '12px',
                          marginBottom: '0px',
                          float: 'right',
                          color: '#0000007d',
                        }}
                      >
                        No
                      </p>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6} lg={6}>
                      <GridContainer>
                        <GridItem xs={12} sm={6} md={6} lg={6}>
                          <p
                            style={{
                              fontWeight: 'bold',
                              fontSize: '12px',
                              marginBottom: '0px',
                              float: 'left',
                              paddingLeft: '8px',
                              color: '#0000007d',
                            }}
                          >
                            From {' --> '}
                            To
                          </p>
                        </GridItem>
                        <GridItem xs={12} sm={2} md={2} lg={2}>
                          <p
                            style={{
                              fontSize: '12px',
                              fontWeight: 'bold',
                              marginBottom: '0px',
                              float: 'left',
                              color: '#0000007d',
                            }}
                          />
                        </GridItem>
                        <GridItem
                          xs={12}
                          sm={4}
                          md={4}
                          lg={4}
                          className={classes.cityPadding}
                        >
                          <p
                            style={{
                              fontSize: '12px',
                              fontWeight: 'bold',
                              marginBottom: '0px',
                              float: 'left',
                              paddingLeft: '2px',
                              color: '#0000007d',
                            }}
                          >
                            Transporter
                          </p>
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                    <GridItem xs={12} sm={2} md={2} lg={2}>
                      <p
                        style={{
                          fontSize: '12px',
                          fontWeight: 'bold',
                          marginBottom: '0px',
                          // float: 'left',
                          color: '#0000007d',
                        }}
                      >
                        Trucker
                      </p>
                    </GridItem>
                    <GridItem
                      xs={12}
                      sm={1}
                      md={1}
                      lg={1}
                      className={classes.noPadding}
                    >
                      <p
                        style={{
                          fontSize: '12px',
                          fontWeight: 'bold',
                          marginBottom: '0px',
                          float: 'right',
                          // paddingLeft: '10px',
                          color: '#0000007d',
                        }}
                      >
                        Comm
                      </p>
                    </GridItem>
                    <GridItem xs={12} sm={2} md={2} lg={2}>
                      <p
                        style={{
                          fontWeight: 'bold',
                          fontSize: '12px',
                          marginBottom: '0px',
                          // float: 'right',
                          color: '#0000007d',
                        }}
                      >
                        <span> Frieght</span>
                      </p>
                    </GridItem>
                  </GridContainer>
                </GridItem>
                <GridItem
                  xs={12}
                  sm={3}
                  md={3}
                  lg={3}
                  // className={classes.paddingLeftBranch}
                >
                  <GridContainer style={{ margin: '0px' }}>
                    <GridItem
                      xs={12}
                      sm={3}
                      md={3}
                      lg={4}
                      className={classes.paddingLeftBranchHeader}
                    >
                      <p
                        style={{
                          fontWeight: 'bold',
                          fontSize: '12px',
                          marginBottom: '0px',
                          float: 'center',
                          color: '#0000007d',
                        }}
                      >
                        <span> Branch</span>
                      </p>
                    </GridItem>
                    <GridItem xs={12} sm={4} md={4} lg={4}>
                      <p
                        style={{
                          fontWeight: 'bold',
                          fontSize: '12px',
                          marginBottom: '0px',
                          float: 'center',
                          color: '#0000007d',
                        }}
                      >
                        <span> Date</span>
                      </p>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </div>
      </GridItem>
    );
  }

  closeChecksAlert() {
    this.setState({
      checksAlert: false,
      checksError: '',
      transaction: null,
      checkOne: false,
      checkTwo: false,
      recentTransactionsLoading: false,
      activeBankDetailsLoading: false,
      activeBankDetails: {},
      recentTransactions: [],
    });
  }

  capitalizeFirstLetter(str) {
    // converting first letter to uppercase
    const role = str === 'branch-ops' ? 'branchOps' : str?.split('is')[0];
    const capitalized = role.charAt(0).toUpperCase() + role.slice(1);

    return capitalized;
  }

  showChecksAlert(transaction) {
    this.setState({
      transaction,
      checksAlert: true,
      checksError: '',
      recentTransactionsLoading: true,
      activeBankDetailsLoading: true,
    });
    const { truckerId, vehicleNumber, transporterId } = transaction;
    const truckerRef = db
      .collection('Truckers')
      .doc(truckerId)
      .get();
    const transporterRef = db
      .collection('Transporters')
      .doc(transporterId)
      .get();
    // Show Active Bank Details to Finance
    const bankRef = db
      .collection('Truckers')
      .doc(truckerId)
      .collection('Banks')
      .get();
    bankRef.then(banksSnap => {
      banksSnap.forEach(bank => {
        if (bank.data().bankAccountActive) {
          this.setState({
            activeBankDetails: bank.data(),
          });
        }
      });
    });
    Promise.all([truckerRef, transporterRef]).then(
      ([truckerResult, transporterResult]) => {
        this.setState({
          truckerContactPerson: truckerResult.data().contactPerson,
          transporterContactPerson: transporterResult.data().contactPerson,
        });
      }
    );
    const recentTransactionsRef = db
      .collection('Transactions')
      .where('truckerId', '==', truckerId)
      .where('vehicleNumber', '==', vehicleNumber)
      .orderBy('createdAt', 'desc')
      .limit(4);
    recentTransactionsRef
      .get()
      .then(snapshot => {
        const recentTransactions = [];
        snapshot.forEach(transactionDoc => {
          if (transaction.transactionId !== transactionDoc.data().transactionId)
            // Don't Include the same transaction in the recent list
            recentTransactions.push(transactionDoc.data());
        });
        if (recentTransactions.length > 3) recentTransactions.pop();
        this.setState({ recentTransactions, recentTransactionsLoading: false });
      })
      .catch(() => {
        this.setState({
          recentTransactions: [],
          recentTransactionsLoading: false,
        });
      });
  }

  handleToggle(value) {
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({
      checked: newChecked,
    });
  }

  // Will Show DetailedTransaction Page
  showDetailedTransaction(transaction) {
    const userDetails = firebase.auth().currentUser;
    const { activeRole } = this.props;
    const role = mapRole(activeRole);
    const auditBody = {
      // data: { ...transaction },
      type: 'view',
      entity: 'Transaction',
      collection: 'Transactions',
      transactionViewedBy: userDetails.uid,
      email: userDetails.email,
      role: activeRole,
      message: `${userDetails.displayName} with email ${userDetails.email} and role ${activeRole} clicked for edit transaction with serial ${transaction.transactionSerial} and id ${transaction.transactionId} from transactions`,
      viewedAt: moment().format('llll'),
    };
    auditLog(auditBody);
    const auditPayload = {
      collection: 'Transactions',
      orgId: null,
      data: {},
      message: `${userDetails.displayName} with email ${userDetails.email} and role ${activeRole} clicked for edit transaction with serial ${transaction.transactionSerial} and id ${transaction.transactionId} from transactions`,
      systemDetails: {},
      type: 'Single View Transaction',
      role: `${this.capitalizeFirstLetter(role)}`,
      eventDateTime: new Date().toISOString(),
      source: 'office',
    };
    auditLogger(auditPayload);

    // Set the CurrentTransaction in Redux Store
    // eslint-disable-next-line react/destructuring-assignment
    history.push({
      pathname: `/${activeRole}/transactions/view/${transaction.transactionId}`,
      state: { selectedBranch: this.props.selectedBranch },
    });
    this.props.setCurrentTransaction(
      transaction,
      transaction.currentPayableAmount,
      transaction.currentReceivableAmount
    );

    const unsubScribeTransaction = db
      .collection('Transactions')
      .doc(transaction.transactionId)
      .onSnapshot(transactionResult => {
        const formattedTransaction = {
          ...transactionResult.data(),
        };
        this.props.setCurrentTransaction(
          formattedTransaction,
          formattedTransaction.currentPayableAmount,
          formattedTransaction.currentReceivableAmount
        );
      });
    //  Save the unsubscribe function of the onSnapshot Listener. This listener will be unscubscribed on componentWillUnmount of the DetailedTransactions Page
    this.props.setCurrentTransactionUnsubscribe(unsubScribeTransaction);
  }

  showModifyPage(transaction) {
    const userDetails = firebase.auth().currentUser;
    const { activeRole } = this.props;
    const role = mapRole(activeRole);
    const auditBody = {
      // data: { ...transaction },
      type: 'edit',
      entity: 'Transaction',
      collection: 'Transactions',
      transactionViewedBy: userDetails.uid,
      email: userDetails.email,
      role: activeRole,
      message: `${userDetails.displayName} with email ${userDetails.email} and role ${activeRole} clicked for edit transaction with serial ${transaction.transactionSerial} and id ${transaction.transactionId} from transactions`,
      viewedAt: moment().format('llll'),
    };
    auditLog(auditBody);
    const auditPayload = {
      collection: 'Transactions',
      orgId: null,
      data: {},
      message: `${userDetails.displayName} with email ${userDetails.email} and role ${activeRole} clicked for edit transaction with serial ${transaction.transactionSerial} and id ${transaction.transactionId} from transactions`,
      systemDetails: {},
      type: 'Edit View Transaction',
      role: `${this.capitalizeFirstLetter(role)}`,
      eventDateTime: new Date().toISOString(),
      source: 'office',
    };
    auditLogger(auditPayload);
    this.props.setCurrentTransaction(
      transaction,
      transaction.currentPayableAmount,
      transaction.currentReceivableAmount
    );
    this.props.switchModifyPage(true);
  }

  handleCheckOne() {
    const { transactions } = this.props;
    const { transaction, checkOne } = this.state;
    const id = transaction.transactionId;
    const index = transactions.map(e => e.transactionId).indexOf(id);
    transactions[index].checkOne = !transactions[index].checkOne;
    transaction.checkOne = !transaction.checkOne;
    this.setState({ transaction, checkOne: !checkOne });
    this.props.setTransactions(transactions);
  }

  handleCheckTwo() {
    const { transactions } = this.props;
    const { transaction, checkTwo } = this.state;

    const id = transaction.transactionId;
    const index = transactions.map(e => e.transactionId).indexOf(id);
    transactions[index].checkTwo = !transactions[index].checkTwo;
    transaction.checkTwo = !transaction.checkTwo;
    this.setState({ transaction, checkTwo: !checkTwo });
    this.props.setTransactions(transactions);
  }

  finishChecks() {
    this.setState({
      checksLoading: true,
    });
    const { transaction, checkOne, checkTwo } = this.state;
    // if (transaction.tdsTransporter)
    // transporterDeductions += Number(transaction.tds);
    db.collection('Transactions')
      .doc(transaction.transactionId)
      .update({
        checkOne,
        checkTwo,
        showAthModal: true,
        modifiedAt: new Date(),
      })
      .then(() => {
        const { transactions } = this.props;
        const id = transaction.transactionId;
        const index = transactions.map(e => e.transactionId).indexOf(id);
        transactions[index].checkOne = checkOne;
        transactions[index].checkTwo = checkTwo;
        this.props.setTransactions(transactions);
        this.setState({
          checksAlert: false,
          transaction: null,
          checksLoading: false,
          recentTransactionsLoading: false,
          recentTransactions: [],
        });
      });
  }

  /**
   * Will Return Array of Transaction Components
   * @function
   */
  showTransactions() {
    const { transactions } = this.props;
    return (
      <GridContainer>
        <Desktop>{this.getTableHeadings()}</Desktop>

        {transactions.map(
          transactionResult => (
            // this.props.searchText !== '' &&
            // transactionResult.transactionSerial.toString().indexOf(this.props.searchText) ===
            //   -1 ? null : (
            <GridItem
              xs={12}
              sm={12}
              md={12}
              lg={12}
              key={transactionResult.transactionId}
            >
              <Desktop>{this.getDesktopCard(transactionResult)}</Desktop>
              <Mobile>{this.getMobileCard(transactionResult)}</Mobile>
            </GridItem>
          )
          //   )
        )}
      </GridContainer>
    );
  }

  render() {
    const { classes } = this.props;
    const {
      checksAlert,
      checkOne,
      checksError,
      checksLoading,
      checkTwo,
      recentTransactions,
      recentTransactionsLoading,
      activeBankDetails,
    } = this.state;
    const myActiveBankDetails = [];
    myActiveBankDetails.push(activeBankDetails);
    return (
      <GridContainer justify="center">
        {this.showTransactions()}
        {checksAlert && (
          <Dialog
            className={classes.modal}
            open={this.state.checksAlert}
            keepMounted
            onClose={() => this.closeChecksAlert()}
          >
            <DialogTitle className={classes.modalHeader}>
              <GridContainer>
                <GridItem xs={2} md={3} lg={3} />
                <GridItem xs={8} md={8} lg={8}>
                  <h3 className={classes.rajdhaniFont}>Finance Approval</h3>
                </GridItem>
                <GridItem xs={2} md={1} lg={1} style={{ color: 'red' }}>
                  <Button
                    justIcon
                    className={classes.modalCloseButton}
                    key="close"
                    aria-label="Close"
                    color="transparent"
                    onClick={() => this.closeChecksAlert()}
                  >
                    <Close className={classes.modalClose} />
                  </Button>
                </GridItem>
              </GridContainer>
            </DialogTitle>
            <DialogContent className={classes.modalBody}>
              <GridContainer>
                <GridItem xs={12}>
                  <div className={classes.checkboxAndRadio}>
                    <FormControlLabel
                      style={{ float: 'left' }}
                      control={
                        <Checkbox
                          checked={checkOne || false}
                          onChange={() => this.handleCheckOne()}
                          value="checkOne"
                        />
                      }
                      label="Has this Transporter been doing regular business with us?"
                    />
                  </div>
                </GridItem>
                <GridItem xs={12}>
                  <div className={classes.checkboxAndRadio}>
                    <FormControlLabel
                      style={{ float: 'left' }}
                      control={
                        <Checkbox
                          checked={checkTwo || false}
                          onChange={() => this.handleCheckTwo()}
                          value="checkTwo"
                        />
                      }
                      label="There are no outstanding issues currently."
                    />
                  </div>
                </GridItem>
                <GridItem xs={12}>
                  {recentTransactionsLoading ? (
                    <GridContainer>
                      <GridItem xs={6} />
                      <GridItem xs={6}>
                        <CircularProgress
                          className={classes.progress}
                          style={{ color: purple[500], justify: 'center' }}
                          thickness={3}
                        />
                      </GridItem>
                    </GridContainer>
                  ) : (
                    <React.Fragment>
                      <h4 className={classes.rajdhaniFont}>
                        Recent Transactions
                      </h4>
                      {recentTransactions.length > 0 ? (
                        <Table
                          tableHeaderColor="primary"
                          tableHead={['Date', 'Truck', 'Freight', 'To', 'From']}
                          tableData={recentTransactions.map(transaction => [
                            moment(transaction.createdAt.toDate()).format(
                              'MMM Do Y'
                            ),
                            transaction.vehicleNumber,
                            transaction.amount,
                            transaction.toCity,
                            transaction.fromCity,
                          ])}
                          coloredColls={[3]}
                          colorsColls={['primary']}
                        />
                      ) : (
                        <h5 className={classes.rajdhaniFont}>
                          No Previous Transactions with this Truck
                        </h5>
                      )}
                      <h4 className={classes.rajdhaniFont}>
                        Active Bank Account
                      </h4>

                      {Object.keys(activeBankDetails).length !== 0 &&
                      activeBankDetails.constructor === Object ? (
                        <Table
                          tableHeaderColor="primary"
                          tableHead={[
                            'Name',
                            'Account No',
                            'Bank',
                            'Ifsc',
                            'Branch',
                          ]}
                          tableData={myActiveBankDetails.map(transaction => [
                            activeBankDetails.bankHolderName,
                            activeBankDetails.accountNumber,
                            activeBankDetails.bankName,
                            activeBankDetails.ifsc,
                            activeBankDetails.bankBranchName,
                          ])}
                        />
                      ) : (
                        <h5 className={classes.rajdhaniFont}>
                          No Bank Accounts Are Active Please Check
                        </h5>
                      )}
                    </React.Fragment>
                  )}
                </GridItem>
                <GridItem xs={12}>
                  <span
                    style={{ color: '#f44336' }}
                    className={classes.rajdhaniFont}
                  >
                    {checksError}
                  </span>
                  {checksLoading ? (
                    <CircularProgress
                      className={classes.progress}
                      style={{ color: purple[500] }}
                      thickness={7}
                    />
                  ) : null}
                </GridItem>
              </GridContainer>
            </DialogContent>
            <DialogActions
              className={`${classes.modalFooter} ${classes.modalFooterCenter}`}
            >
              {this.finishChecks}
              <Button onClick={() => this.closeChecksAlert()} color="danger">
                Cancel
              </Button>
              <Button onClick={() => this.finishChecks()} color="success">
                Submit
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </GridContainer>
    );
  }
}

TransactionsCard.propTypes = {
  classes: PropTypes.shape({}).isRequired,
};

const mapStateToProps = state => ({
  activeRole: state.main.activeRole,
  isDetailedPage: state.transactions.isDetailedPage,
  currerntTransactionData: state.transactions.currerntTransactionData,
  allTransactions: state.transactions.transactions,
  userDetails: state.main.userDetails,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      switchActivePage,
      setCurrentTransaction,
      setCurrentTransactionUnsubscribe,
      setTransactions,
      switchModifyPage,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(TransactionStyle)(TransactionsCard));
