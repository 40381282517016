import { Button, CircularProgress, Paper, makeStyles } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useDropzone } from 'react-dropzone';
import moment from 'moment';
import Box from '@material-ui/core/Box';
import axios from 'axios';
import swal from 'sweetalert';
import myFirebase from '../../../config/config';
import apiCaller from '../../../utils/apiCallerOutstanding';
import FeatureFlagEmptyState from '../../Components/FeatureFlagEmptyState';
import getFileMimeType from '../../../utils/getFileMimeType';
import mapRole from '../../../utils/roleMapping';

const inlineCheckIconStyle = {
  marginRight: '5px',
  color: '#52BE56',
};
const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    border: '2px dashed lightgray',
    borderRadius: '5px',
    margin: '14px 20px',
  },
  viewButton: {
    background: '#52BE56',
    color: 'white',
    margin: '2px 20px 0px 0px',
    padding: '5px 42px 5px 10px',
  },
  headerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));
const nameBoxStyles = {
  lineHeight: '10px',
  margin: '10px 0px 0px 20px',
};
const paperStyles = { padding: 10, marginTop: 20 };

const TruckerTdsCreate = ({ truckerId, featureFlagFiles, activeRole }) => {
  const classes = useStyles();

  const role = mapRole(activeRole);
  const currentActiveRole = `is${role?.charAt(0).toUpperCase()}${role?.slice(
    1
  )}`;

  const [uploading, setUploading] = useState(false);
  const [token, setToken] = useState('');
  const [tdsUploadData, setTdsUploadData] = useState([]);

  const getTruckerTDSUrl = async () => {
    const endPoint = `pipe/trucker-tds/signed-url?trucker_id=${truckerId}&role=${currentActiveRole}&file_type=${truckerTds}`;
    try {
      const response = await apiCaller(
        endPoint,
        {},
        `Bearer ${token}`,
        'nh-base',
        'get'
      );
      const fileUrl = response?.data.file_url;
      if (fileUrl) {
        window.open(fileUrl, '_blank');
      }
    } catch (error) {}
  };

  const getTdsList = async Token => {
    const endPoint = `pipe/trucker-tds/files-list?role=${currentActiveRole}&trucker_id=${truckerId}`;
    try {
      const response = await apiCaller(
        endPoint,
        {},
        `Bearer ${Token}`,
        'nh-base',
        'get'
      );
      const data = response?.data.Data;
      setTdsUploadData(data);
    } catch (error) {
      console.error('Error fetching TDS list:', error);
    }
  };

  const fetchS3URl = async UploadedFile => {
    const mime = await getFileMimeType(UploadedFile);
    if (mime !== 'Unknown file type') {
      let fileUrl = '';
      let metaData = '';
      const body = {
        role: currentActiveRole,
        financial_year: currentFinancialYear,
        extension: mime,
        trucker_id: truckerId,
      };
      const endPoint = `pipe/trucker-tds/signed-url`;

      try {
        const response = await apiCaller(
          endPoint,
          body,
          `Bearer ${token}`,
          'nh-base',
          'post'
        );
        fileUrl = response?.data.Data.signed_url;
        metaData = response?.data.metadata;
      } catch (e) {
        fileUrl = null;
        metaData = null;
      }
      return { url: fileUrl, metaDataValue: metaData };
    }
    swal('Oops!', 'Unknown file type. Please upload a valid file.', 'error');
    setUploading(false);

    return { url: null, metaDataValue: null };
  };

  const onDrop = async acceptedFiles => {
    setUploading(true);
    const { url, metaDataValue } = await fetchS3URl(acceptedFiles[0]);
    if (url) {
      try {
        await axios.put(url, acceptedFiles[0], {
          headers: {
            'Content-Type': acceptedFiles[0]?.type,
            'x-amz-meta-metadata': metaDataValue,
          },
        });
        setTimeout(() => {
          getTdsList(token);
          setUploading(false);
        }, 5000);
      } catch (e) {
        setUploading(false);
      }
    } else {
      setUploading(false);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    acceptedFiles: {
      'image/png': ['.png', '.jpeg', '.jpg'],
      'application/pdf': ['.pdf'],
    },
    onDrop,
    maxFiles: 1,
  });

  function getFinancialYearAndTruckerTds() {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth();

    const startYear = month >= 3 ? year : year - 1;
    const endYear = startYear + 1;

    const currentFinancialYear = `${startYear}-${endYear}`;
    const formattedYear = `${startYear}${endYear.toString().slice(-2)}`;
    const truckerTds = `trucker_tds_${formattedYear}`;

    return { currentFinancialYear, truckerTds };
  }

  const { currentFinancialYear, truckerTds } = getFinancialYearAndTruckerTds();

  useEffect(() => {
    const currentUserDetails = myFirebase.auth().currentUser;
    const { Aa } = currentUserDetails;
    const userToken = Aa;
    setToken(userToken);
  }, []);

  return (
    <Paper elevation={1} style={paperStyles}>
      {featureFlagFiles === 1 ? (
        <FeatureFlagEmptyState color="black" />
      ) : (
        <>
          <div className={classes.headerWrapper}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <p
                style={{
                  margin: '14px 20px',
                  textAlign: 'left',
                  fontSize: '26px',
                  fontWeight: 400,
                }}
              >
                TDS Declaration ({currentFinancialYear})
              </p>
              {tdsUploadData.length > 0 ? (
                <CheckCircleIcon style={inlineCheckIconStyle} />
              ) : (
                ''
              )}
            </div>

            {tdsUploadData.length > 0 ? (
              <Box>
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  className={classes.viewButton}
                  onClick={getTruckerTDSUrl}
                  startIcon={<VisibilityIcon style={{ marginRight: '23px' }} />}
                >
                  View
                </Button>
              </Box>
            ) : (
              ''
            )}
          </div>
          <Box>
            {tdsUploadData.length === 0 ? (
              <Box className={classes.container}>
                {uploading ? (
                  <CircularProgress
                    style={{ color: '#FCD21F', margin: '5px' }}
                    size={30}
                    thickness={8}
                  />
                ) : (
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        marginTop: 5,
                      }}
                    >
                      <CloudUploadIcon />
                      <p style={{ fontSize: '10px', color: 'gray' }}>
                        Drag and Drop or Click here to upload
                      </p>
                    </div>
                  </div>
                )}
              </Box>
            ) : (
              <div style={nameBoxStyles}>
                <p style={{ fontWeight: 'bold' }}>
                  {tdsUploadData[0]?.uploaded_by}
                </p>
                <p style={{ color: '#a9a9a9', fontSize: '10px' }}>
                  {moment(tdsUploadData[0]?.uploaded_at).format('LLL')}
                </p>
              </div>
            )}
          </Box>
        </>
      )}
    </Paper>
  );
};

export default TruckerTdsCreate;
