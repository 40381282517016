/* eslint-disable react/no-deprecated */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable class-methods-use-this */
import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import { Typography } from '@material-ui/core';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TransactionStyle from 'assets/jss/material-dashboard-pro-react/views/TransactionStyle';
import CircularProgress from '@material-ui/core/CircularProgress';
import purple from '@material-ui/core/colors/purple';

import moment from 'moment';
import firebase from '../../../config/config';
import apiCaller from '../../../utils/apiCallerOutstanding';

const addComment = firebase
  .functions()
  .httpsCallable('transactions-addComment');

const db = firebase.firestore();

class CommentSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      payouts: [],
      message: '',
      messageState: '',
      isLoading: false,
      comments: [],
    };
    this.submitComment = this.submitComment.bind(this);
    this.getComments = this.getComments.bind(this);
  }

  componentDidMount() {
    // this.getComments();
    this.getTruckerComments();
  }

  getComments() {
    const { currentTransactionData } = this.props;
    const { transactionId } = currentTransactionData;
    const commentRef = db
      .collection('Transactions')
      .doc(transactionId)
      .collection('Comments')
      .orderBy('createdAt', 'desc');
    commentRef.onSnapshot(commentSnap => {
      const comments = [];
      commentSnap.forEach(commentDoc => {
        comments.push(commentDoc.data());
      });
      this.setState({
        comments,
      });
    });
  }

  // validates all required fields and returns a promise

  /**
   * will return the notification snackbar
   * @param {string} place
   */
  showNotification(place) {
    // eslint-disable-next-line react/destructuring-assignment
    if (!this.state[place]) {
      const x = [];
      x[place] = true;
      this.setState(x);
      setTimeout(() => {
        x[place] = false;
        this.setState(x);
      }, 5000);
    }
  }

  // function that verifies if value contains only numbers
  verifyNumber(value) {
    const numberRex = new RegExp('^[0-9]+$');
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  }

  verifyOtp(value) {
    const numberRex = new RegExp('^[0-9]+$');
    if (numberRex.test(value) && value.toString().length > 5) {
      return true;
    }
    return false;
  }

  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }

  // function that verifies if a string has a given length or not
  verifyNarration(value) {
    // const emailRex = /[^\s@]+@[^\s@]+\.[^\s@]+/;
    return true;
  }

  async getTruckerComments() {
    const endPoint = 'add-and-get-comments';
    const user = firebase.auth().currentUser;
    const { Aa } = user;
    const { currentTransactionData } = this.props;
    const { transactionId } = currentTransactionData;
    const body = {
      type: 'finance_transactions',
      channel_id: transactionId,
    };

    await apiCaller(endPoint, body, `Bearer ${Aa}`)
      .then(response => {
        this.setState({ comments: response?.data?.body });
      })
      .catch(error => console.log(error));
  }

  submitComment() {
    const user = firebase.auth().currentUser;
    const { Aa } = user;
    const { message } = this.state;
    const { currentTransactionData, userDetails } = this.props;
    const { transactionId } = currentTransactionData;
    this.setState({
      isLoading: true,
    });
    this.validateAllFields()
      .then(success => {
        const body = {
          type: 'finance_transactions',
          comment: message,
          channel_id: transactionId,
        };
        apiCaller('add-and-get-comments', body, `Bearer ${Aa}`)
          .then(response => {
            this.setState({
              isLoading: false,
              message: '',
            });
            this.getTruckerComments();
          })
          .catch(err => {
            this.setState({
              isLoading: false,
              message: '',
            });
          });
      })
      .catch(err => {
        this.setState({
          isLoading: false,
        });
      });
  }

  validateAllFields() {
    const { message } = this.state;
    let error = false;
    return new Promise((resolve, reject) => {
      // Verify  message
      if (this.verifyLength(message, 2)) {
        this.setState({
          messageState: 'success',
        });
      } else {
        this.setState({ messageState: 'error' });
        error = true;
      }

      if (!error) {
        resolve(true);
      } else {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject('validation Failed');
      }
    });
  }

  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case 'number':
        if (this.verifyNumber(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'length':
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'otp':
        if (this.verifyOtp(event.target.value, stateNameEqualTo)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'narration':
        if (this.verifyNarration(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      default:
        this.setState({
          [`${stateName}State`]: 'success',
          [stateName]: event.target.value,
        });
        break;
    }
  }

  displayComments() {
    const { comments } = this.state;

    const { classes } = this.props;
    return (
      <React.Fragment>
        {comments.length <= 0 ? (
          <p className={classes.textCenter}>No Comments Available</p>
        ) : (
          <div style={{ margin: '10px' }}>
            {comments?.map(comment => (
              <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                <div
                  style={{
                    display: 'inline-block',
                    width: '36px',
                    height: '36px',
                    borderRadius: '50%',
                    backgroundColor: '#C2185B',
                    textAlign: 'center',
                    flexShrink: 0,
                    lineHeight: '36px',
                    marginBottom: '20px',
                  }}
                >
                  <Typography style={{ color: 'white', lineHeight: '36px' }}>
                    {comment?.commented_by_name
                      ? comment?.commented_by_name.charAt(0)
                      : ''}
                  </Typography>
                </div>
                <div>
                  <div
                    style={{
                      marginBottom: '15px',
                      backgroundColor: '#EFF6FC',
                      marginLeft: '10px',
                      borderRadius: '4px',
                    }}
                  >
                    <Typography
                      style={{
                        paddingLeft: '10px',
                        paddingRight: '10px',
                        paddingTop: '7px',
                        marginBottom: '2px',
                        textAlign: 'left',
                      }}
                    >
                      {comment?.comment}
                    </Typography>
                    <Typography
                      variant="caption"
                      display="block"
                      gutterBottom
                      color="textSecondary"
                      style={{
                        paddingLeft: '10px',
                        paddingRight: '10px',
                        paddingBottom: '7px',
                        textAlign: 'left',
                      }}
                    >
                      {comment?.commented_by_name}
                      &nbsp; &nbsp; &nbsp;
                      {moment(comment?.created_at).format('DD-MM-YYYY')}
                      &nbsp;
                      {moment(comment?.created_at).format('hh:mm A')}
                    </Typography>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </React.Fragment>
    );
  }

  render() {
    const { message, messageState, isLoading } = this.state;
    const { activeRole, classes } = this.props;
    return (
      <Card>
        <CardHeader
          className={`${classes.cardHeader}`}
          style={{
            marginBottom: '1px',
            paddingBottom: '1px',
            paddingTop: '5px',
          }}
        >
          <h3 className={classes.cardTitle} style={{ float: 'left' }}>
            Comments
          </h3>
        </CardHeader>
        <form
          onSubmit={e => {
            this.submitComment(this);
            e.preventDefault();
          }}
        >
          <CardBody>
            <div
              style={{
                maxHeight: 250,
                overflow: 'auto',
                overflowX: 'hidden',
                paddingTop: '5px',
                paddingBottom: '5px',
              }}
            >
              {this.displayComments()}
            </div>
            {activeRole === 'finance' ||
            activeRole === 'transaction-view' ||
            activeRole === 'finance-recievable' ||
            activeRole === 'finance-recon' ||
            activeRole === 'finance-payable' ? (
              <React.Fragment>
                <GridContainer>
                  <GridItem xs={12} sm={10} md={9} lg={9}>
                    <CustomInput
                      labelText="Add Comment"
                      id="about-me"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: message,
                        onChange: event =>
                          this.change(event, 'message', 'narration'),
                        type: 'length',
                      }}
                      success={messageState === 'success'}
                      error={messageState === 'error'}
                    />
                  </GridItem>
                  <GridItem
                    xs={12}
                    sm={2}
                    md={3}
                    lg={3}
                    style={{
                      display: 'flex',
                      marginBottom: '12px',
                      alignItems: 'flex-end',
                    }}
                  >
                    {isLoading ? (
                      <CircularProgress
                        style={{ color: purple[500] }}
                        thickness={5}
                      />
                    ) : (
                      <Button
                        type="submit"
                        color="info"
                        size="sm"
                        style={{ float: 'left' }}
                        onClick={() => this.submitComment()}
                      >
                        Comment
                      </Button>
                    )}
                  </GridItem>
                </GridContainer>
              </React.Fragment>
            ) : null}
          </CardBody>
        </form>
      </Card>
    );
  }
}

CommentSection.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  isDetailedPage: state.transactions.isDetailedPage,
  currentTransactionData: state.transactions.currentTransactionData,
  transactions: state.transactions.transactions,
  payableTransactions: state.transactions.payableTransactions,
  currentPayableAmount: state.transactions.currentPayableAmount,
  activeRole: state.main.activeRole,
  userDetails: state.main.userDetails,
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(TransactionStyle)(CommentSection));
