import Transactions from 'views/Transactions/FinanceTransactions.jsx';
import DashboardIcon from '@material-ui/icons/Dashboard';
import {
  AssignmentTurnedIn,
  LocalShipping,
  TransferWithinAStation,
} from '@material-ui/icons';
import AdminApproveTransaction from 'views/AdminApproveTransaction/AdminApproveTransaction.jsx';
import Truckers from 'views/Truckers/Truckers.jsx';
import Transporters from 'views/Transporters/Transporters.jsx';

const financeReconDashRoutes = [
  {
    path: '/finance-recon/transactions',
    name: 'Transactions',
    icon: DashboardIcon,
    component: Transactions,
  },
  {
    collapse: true,
    path: '/finance-recon/approve',
    name: 'Approve',
    icon: AssignmentTurnedIn,
    state: 'openApprove',
    views: [
      {
        path: '/finance-recon/approve/transaction',
        name: 'Transaction',
        mini: 'T',
        component: AdminApproveTransaction,
      },
    ],
  },
  {
    path: '/finance-recon/truckers',
    name: 'View Truckers',
    icon: LocalShipping,
    component: Truckers,
  },
  {
    path: '/finance-recon/transporters',
    name: 'View Transporters',
    icon: TransferWithinAStation,
    component: Transporters,
  },
];
export default financeReconDashRoutes;
