/* eslint-disable no-var */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-shadow */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable prefer-destructuring */
/* eslint-disable func-names */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/sort-comp */
import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardBody from 'components/Card/CardBody.jsx';

import AddAlert from '@material-ui/icons/AddAlert';
import Snackbar from 'components/Snackbar/Snackbar.jsx';
import SweetAlert from 'react-bootstrap-sweetalert';
import TransactionsCard from 'views/Transactions/Sections/TransactionsCard';
import { connect } from 'react-redux';

import AdminApprovePageStyle from 'assets/jss/material-dashboard-pro-react/views/AdminApprovePageStyle';
import firebase from '../../config/config';

const db = firebase.firestore();
// var searchText = '';

class AdminApproveTransaction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      company: '',
      companyList: {},
      pendingTransactions: [],
      editedTransactions: [],
      isLoading: false,
      isButtonLoading: [],
      tc: false,
      agentName: '',
      color: 'info',
      searchText: '',
    };
    //  this.handleSimpleSelect = this.handleSimpleSelect.bind(this);
    this.approveTransaction = this.approveTransaction.bind(this);
    this.modifyTransaction = this.modifyTransaction.bind(this);
    this.inputAlert = this.inputAlert.bind(this);
    this.rejectTransaction = this.rejectTransaction.bind(this);
    this.getPendingTransactions = this.getPendingTransactions.bind(this);
  }

  componentDidMount() {
    this.getPendingTransactions();
  }

  /**
   * will return the notification snackbar
   * @param {string} place
   */
  showNotification(place, name) {
    this.setState({
      agentName: name,
    });
    if (!this.state[place]) {
      const x = [];
      x[place] = true;
      this.setState(x);
      setTimeout(
        // eslint-disable-next-line prefer-arrow-callback
        function() {
          x[place] = false;
          this.setState(x);
        }.bind(this),
        6000
      );
    }
  }

  handleClickOpen(modal) {
    const x = [];
    x[modal] = true;
    this.setState(x);
  }

  handleClose(modal) {
    const x = [];
    x[modal] = false;
    this.setState(x);
  }

  handleSubmit = event => {
    event.preventDefault();

    // getPendingTransactions();
  };

  /**
   * will return All the Pending Users
   * @function
   * if(this.props.userDetails.branches.includes(transaction.data().branch))
   */
  getPendingTransactions() {
    const self = this;
    const { activeRole } = this.props;
    let TransactionsRef = db
      .collection('Transactions')
      .where('status', '==', 'pending')
      .orderBy('createdAt', 'desc');

    if (activeRole === 'finance' || activeRole === 'finance-recon') {
      TransactionsRef = TransactionsRef.where(
        'isEditedTransaction',
        '==',
        true
      );
    }

    TransactionsRef.onSnapshot(
      transactionDoc => {
        const pendingTransactions = [];
        if (this.props.userDetails.isOps && !this.props.userDetails.isAdmin) {
          if (!transactionDoc.empty) {
            transactionDoc.forEach(doc => {
              if (this.props.userDetails.branches.includes(doc.data().branch))
                pendingTransactions.push(doc.data());
            });
            this.setState({ pendingTransactions });
          } else {
            self.setState({ pendingTransactions, isLoading: false });
          }
        } else if (
          this.props.userDetails.isAdmin ||
          activeRole === 'finance' ||
          activeRole === 'finance-recon'
        ) {
          // eslint-disable-next-line no-lonely-if
          if (!transactionDoc.empty) {
            transactionDoc.forEach(doc => {
              pendingTransactions.push(doc.data());
            });
            this.setState({ pendingTransactions });
          } else {
            self.setState({ pendingTransactions, isLoading: false });
          }
        }
      },
      err => {
        console.log('no users found error', err);
      }
    );
  }

  /**
   * will return the Card
   * @function
   */
  getCards() {
    const { classes } = this.props;
    return (
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={12} lg={12}>
          <Card>
            <CardHeader className={`${classes.cardHeader}`} color="info">
              <GridContainer>
                <GridItem xs={12} md={3} lg={3}>
                  <p
                    className={`${classes.cardHeader} ${classes.rajdhaniFont}`}
                  >
                    Approve Transactions
                  </p>
                  <h3
                    className={`${classes.cardTitle} ${classes.rajdhaniFont}`}
                  >
                    {Number(this.state.pendingTransactions.length)} <small />
                  </h3>
                </GridItem>
                {/* <GridItem xs={12} md={7} lg={8}>
                  <div style={{ float: 'right' }}>
                    <form onSubmit={this.handleSubmit}>
                      <input
                        onChange={event =>
                          this.setState({ searchText: event.target.value })
                        }
                      />
                    </form>
                  </div>
                </GridItem> */}
              </GridContainer>
            </CardHeader>
            <CardBody className={`${classes.cardHeader} ${classes.textCenter}`}>
              {Number(this.state.pendingTransactions.length) <= 0 ? (
                <p className={classes.textCenter}>
                  No pending transactions available
                </p>
              ) : null}
              <TransactionsCard
                searchText={this.state.searchText}
                admin
                approve
                transactions={[...this.state.pendingTransactions]}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }

  /**
   * Approve User
   * @function
   */
  approveTransaction(transaction, i) {
    const { isButtonLoading } = this.state;
    isButtonLoading[i] = true;
    this.setState({
      isButtonLoading,
    });
    const transactionRef = db.collection(`Transactions`);

    transactionRef
      .doc(`${transaction.transactionId}`)
      .update({
        status: 'approved',
        modifiedAt: new Date(),
      })
      .then(() => {
        const pendingTransactions = this.state.pendingTransactions;
        for (const i in pendingTransactions) {
          if (
            pendingTransactions[i].transactionId === transaction.transactionId
          ) {
            pendingTransactions.splice(i, 1);
          }
        }
        const NotificationMessage = `#${transaction.transactionSerial} Transaction Approved `;
        this.showNotification('tc', NotificationMessage);
        this.setState({
          pendingTransactions,
          isButtonLoading: false,
          color: 'info',
        });
      });
  }

  /**
   * Reject User
   * @function
   */
  rejectTransaction(transaction, i) {
    const { isButtonLoading } = this.state;
    isButtonLoading[i] = true;
    this.setState({
      isButtonLoading,
    });
    const transactionRef = db.collection(`Transactions`);
    transactionRef
      .doc(`${transaction.transactionId}`)
      .update({ status: 'rejected', modifiedAt: new Date() })
      .then(() => {
        const pendingTransactions = this.state.pendingTransactions;
        for (const i in pendingTransactions) {
          if (
            pendingTransactions[i].transactionId === transaction.transactionId
          ) {
            pendingTransactions.splice(i, 1);
          }
        }
        const NotificationMessage = `#${transaction.transactionSerial} Transaction Rejected `;
        this.showNotification('tc', NotificationMessage);
        this.setState({
          pendingTransactions,
          isButtonLoading: false,
          color: 'danger',
        });
      })
      .catch(() => {
        this.setState({ isButtonLoading: false });
      });
  }

  inputAlert(transaction) {
    this.setState({
      transaction,
      alert: (
        <SweetAlert
          input
          showCancel
          style={{ display: 'block', marginTop: '-100px' }}
          title="Add Comment"
          onConfirm={e => this.inputConfirmAlert(e)}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={`${this.props.classes.button} ${this.props.classes.info}`}
          cancelBtnCssClass={`${this.props.classes.button} ${this.props.classes.danger}`}
        />
      ),
    });
  }

  inputConfirmAlert(e) {
    this.setState({ comment: e, alert: null }, this.modifyTransaction);

    //  setTimeout(this.inputConfirmAlertNext, 200);
  }

  hideAlert() {
    this.setState({
      alert: null,
    });
  }

  /**
   * Request to Modify User
   * @function
   */
  modifyTransaction() {
    const { transaction, comment } = this.state;
    // const comment = alert; // Modification Comment
    this.setState({
      isButtonLoading: true,
    });
    const transactionRef = db.collection(`Transactions`);
    transactionRef
      .doc(`${transaction.transactionId}`)
      .update({
        status: 'modify',
        comment,
        modifiedAt: new Date(),
      })
      .then(() => {
        const pendingTransactions = this.state.pendingTransactions;
        for (const i in pendingTransactions) {
          if (
            pendingTransactions[i].transactionId === transaction.transactionId
          ) {
            pendingTransactions.splice(i, 1);
          }
        }
        const NotificationMessage = `#${transaction.transactionSerial} Transaction has been requested for modification `;
        this.showNotification('tc', NotificationMessage);
        this.setState({
          pendingTransactions,
          isButtonLoading: false,
          color: 'danger',
        });
      })
      .catch(() => {
        this.setState({ isButtonLoading: false });
      });
  }

  render() {
    return (
      <div>
        <GridContainer justify="center">
          {this.state.alert}

          <GridItem xs={12} sm={12} md={12}>
            {this.getCards()}
          </GridItem>
          <Snackbar
            place="tc"
            color={this.state.color}
            icon={AddAlert}
            message={this.state.agentName}
            open={this.state.tc}
            closeNotification={() => this.setState({ tc: false })}
            close
          />
        </GridContainer>
      </div>
    );
  }
}

AdminApproveTransaction.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  isDetailedPage: state.transactions.isDetailedPage,
  transactions: state.transactions.transactions,
  activeRole: state.main.activeRole,
  userDetails: state.main.userDetails,
});

export default connect(mapStateToProps)(
  withStyles(AdminApprovePageStyle)(AdminApproveTransaction)
);
