/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/no-did-update-set-state */
/* eslint-disable react/no-string-refs */
/* eslint-disable no-shadow */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/sort-comp */
import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';
// creates a beautiful scrollbar
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import Header from 'components/Header/Header.jsx';
import Footer from 'components/Footer/Footer.jsx';
import Sidebar from 'components/Sidebar/Sidebar.jsx';

import financePayableDashRoutes from 'routes/financePayableDashRoutes.jsx';

// import RequireAuth from "../../app/auth";
import firebase from 'firebase/app';

import appStyle from 'assets/jss/material-dashboard-pro-react/layouts/dashboardStyle.jsx';

import logo from 'assets/img/Lobb-Logo.png';
import { setActiveRole } from '../reducers/main';
import DetailedTransactions from '../views/Transactions/Sections/DetailedTransactions';
import AdminEditTrucker from '../views/Truckers/AdminEditTrucker';
import AdminEditTransporter from '../views/Transporters/AdminEditTransporter';

const switchRoutes = (
  <Switch>
    <Route
      path="/finance-payable/transactions/view/:txnId"
      component={DetailedTransactions}
    />
    <Route
      path="/finance-payable/truckers/view/:truckerId"
      component={AdminEditTrucker}
    />
    <Route
      path="/finance-payable/transporters/view/:transporterId"
      component={AdminEditTransporter}
    />
    {financePayableDashRoutes.map((prop, key) => {
      if (prop.redirect)
        return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
      if (prop.collapse)
        return prop.views.map((prop, key) => (
          <Route path={prop.path} component={prop.component} key={key} />
        ));
      return <Route path={prop.path} component={prop.component} key={key} />;
    })}
  </Switch>
);

class FinanceDashboard extends React.Component {
  state = {
    mobileOpen: false,
    miniActive: false,
    name: '',
    isFinancePayable: false,
    gender: 'male',
  };

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  getRoute() {
    return this.props.location.pathname !== '/maps/full-screen-maps';
  }

  componentDidMount() {
    this.props.setActiveRole('finance-payable');
    const db = firebase.firestore();
    const usersRef = db.collection('Users');
    // if (navigator.platform.indexOf('Win') > -1) {
    //   ps = new PerfectScrollbar(this.refs.mainPanel, {
    //     suppressScrollX: true,
    //     suppressScrollY: false,
    //   });
    //   document.body.style.overflow = 'hidden';
    // }
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        // Check if user already exists
        usersRef
          .doc(user.uid)
          .get()
          .then(userDoc => {
            if (userDoc.exists) {
              // Succeess
              if (userDoc.data().isFinancePayable === true) {
                this.setState({
                  name: userDoc.data().name,
                  gender: userDoc.data().gender,
                  isFinancePayable: true,
                });
              } else {
                this.setState({ isFinancePayable: false });
                this.props.history.push('/pages/initial-page');
              }
            } else {
              this.setState({ isFinancePayable: false });
              this.props.history.push('/pages/initial-page');
            }
          });
      } else {
        // if not logged go to login screen
        this.props.history.push('/pages/login-page');
      }
    });
  }

  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      this.refs.mainPanel.scrollTop = 0;
      if (this.state.mobileOpen) {
        this.setState({ mobileOpen: false });
      }
    }
  }

  sidebarMinimize() {
    this.setState({ miniActive: !this.state.miniActive });
  }

  render() {
    const { classes, ...rest } = this.props;
    const mainPanel = `${classes.mainPanel} ${cx({
      [classes.mainPanelSidebarMini]: this.state.miniActive,
      [navigator.platform.indexOf('Win') > -1]: (
        <PerfectScrollbar>{this.refs.mainPanel}</PerfectScrollbar>
      ),
    })}`;
    return (
      <div className={classes.wrapper}>
        {this.state.isFinancePayable ? (
          <React.Fragment>
            <Sidebar
              gender={this.state.gender}
              routes={financePayableDashRoutes}
              logoText="Lobb Office"
              logo={logo} // image={image}
              name={this.state.name}
              handleDrawerToggle={this.handleDrawerToggle}
              open={this.state.mobileOpen}
              color="blue"
              bgColor="white"
              miniActive={this.state.miniActive}
              {...rest}
            />
            <div className={mainPanel} ref="mainPanel">
              <Header
                sidebarMinimize={this.sidebarMinimize.bind(this)}
                miniActive={this.state.miniActive}
                routes={financePayableDashRoutes}
                handleDrawerToggle={this.handleDrawerToggle}
                {...rest}
              />
              {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
              {this.getRoute() ? (
                <div className={classes.content}>
                  <div className={classes.container}>{switchRoutes}</div>
                </div>
              ) : (
                <div className={classes.map}>{switchRoutes}</div>
              )}
              {this.getRoute() ? <Footer fluid /> : null}
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Sidebar
              gender=""
              routes={[]}
              logoText="Lobb Office"
              logo={logo} // image={image}
              name={this.state.name}
              handleDrawerToggle={this.handleDrawerToggle}
              open={this.state.mobileOpen}
              color="blue"
              bgColor="white"
              miniActive={this.state.miniActive}
              {...rest}
            />
            <div className={mainPanel} ref="mainPanel" />
          </React.Fragment>
        )}
      </div>
    );
  }
}

FinanceDashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  userDetails: state.main.userDetails,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setActiveRole,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(appStyle)(FinanceDashboard));
