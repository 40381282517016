/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Rating } from 'react-simple-star-rating';
import Card from '@material-ui/core/Card';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import swal from 'sweetalert';
import firebase from '../../../../config/config';

const { REACT_APP_OFFICE_REPORTS_API } = process.env;
const useStyles = makeStyles({
  gridContainer: {
    margin: '15px 0px',
  },
  root: {
    minWidth: 275,
    textAlign: 'left',
  },
  title: { textTransform: 'capitalize', fontSize: 24 },
  titleBox: { display: 'flex', alignItems: 'center', margin: '10px 0px' },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  pos: {
    marginBottom: 12,
  },
  stars: { marginRight: '10px' },
  submitButton: {
    backgroundColor: '#3366FF',
    color: 'white',
    fontSize: '12px',
    textTransform: 'capitalize',
    padding: '5px 16px',
  },
  flex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});

export default function RatingsCard({
  activeRole,
  transactionSerial,
  currentTransactionData,
}) {
  const classes = useStyles();
  const [transporterRating, setTransporterRating] = useState(0);
  const [truckerRating, setTruckerRating] = useState(0);
  const [token, setToken] = useState(''); // initial rating value
  const [fetching, setFetching] = useState(false);
  const {
    truckerId,
    transporterId,
    transporterName,
    truckerName,
  } = currentTransactionData;
  // Catch Rating value

  const submitTransporterRating = async rating => {
    const endpoint = 'transaction-add-ratings';
    const body = {
      transaction_serial: transactionSerial,
      source: 'transporter',
      type: 'add',
      ratings: rating,
      transporter_id: transporterId,
    };
    try {
      await axios.post(`${REACT_APP_OFFICE_REPORTS_API}${endpoint}`, body, {
        headers: { Authorization: `Bearer ${token}` },
      });
      swal(
        'Good job!',
        `You have rated ${transporterName} Transporter!`,
        'success'
      );
    } catch (e) {
      console.log(e);
    }
  };
  const submitTruckerRating = async rating => {
    const endpoint = 'transaction-add-ratings';
    const body = {
      transaction_serial: transactionSerial,
      source: 'trucker',
      type: 'add',
      ratings: rating,
      trucker_id: truckerId,
    };
    try {
      await axios.post(`${REACT_APP_OFFICE_REPORTS_API}${endpoint}`, body, {
        headers: { Authorization: `Bearer ${token}` },
      });
      swal('Good job!', `You have rated ${truckerName} Trucker!`, 'success');
    } catch (e) {
      console.log(e);
    }
  };
  const resetRating = async type => {
    if (type === 'trucker') {
      setTruckerRating(0);
      const body = {
        transaction_serial: transactionSerial,
        source: 'trucker',
        type: 'reset',
        trucker_id: truckerId,
      };
      const endPoint = 'transaction-add-ratings';
      try {
        await axios.post(`${REACT_APP_OFFICE_REPORTS_API}${endPoint}`, body, {
          headers: { Authorization: `Bearer ${token}` },
        });
        swal(
          'Rating reset !',
          `You have reset rating for ${truckerName} Trucker!`,
          'success'
        );
      } catch (e) {
        console.log(e);
      }
    } else if (type === 'transporter') {
      setTransporterRating(0);
      const body = {
        transaction_serial: transactionSerial,
        source: 'transporter',
        type: 'reset',
        transporter_id: transporterId,
      };
      const endPoint = 'transaction-add-ratings';
      try {
        await axios.post(`${REACT_APP_OFFICE_REPORTS_API}${endPoint}`, body, {
          headers: { Authorization: `Bearer ${token}` },
        });
        swal(
          'Rating reset !',
          `You have reset rating for ${transporterName} Transporter!`,
          'success'
        );
      } catch (e) {
        console.log(e);
      }
    }
  };
  const handleReset = type => {
    swal('Are you sure you want to do this?', {
      buttons: {
        cancel: 'No',
        catch: {
          text: 'Yes',
          value: type,
        },
      },
    }).then(value => resetRating(value));
  };
  const getTruckerRating = async Token => {
    setFetching(true);
    const body = {
      transaction_serial: transactionSerial,
    };
    const endPoint = 'transaction-get-ratings';
    try {
      const response = await axios.post(
        `${REACT_APP_OFFICE_REPORTS_API}${endPoint}`,
        body,
        {
          headers: { Authorization: `Bearer ${Token}` },
        }
      );
      const data = response.data.body;
      setTruckerRating(data.truckers_rating * 20 || 0);
      setTransporterRating(data.transporters_rating * 20 || 0);
      setFetching(false);
    } catch (e) {
      console.log(e);
      setFetching(false);
    }
  };
  const handleTransporterRating = async rate => {
    await setTransporterRating(rate / 20);
    await submitTransporterRating(rate / 20);
    // other logic
  };
  const handleTruckerRating = rate => {
    setTruckerRating(rate / 20);
    submitTruckerRating(rate / 20);
    // other logic
  };
  useEffect(() => {
    const userDetails = firebase.auth().currentUser;
    const { Aa } = userDetails;
    setToken(Aa);
    getTruckerRating(Aa);
  }, []);
  return (
    <Grid container className={classes.gridContainer} spacing={2}>
      <Grid item xs={12} md={12} lg={3}>
        <Card className={classes.root}>
          <CardContent>
            <div className={classes.titleBox}>
              {fetching ? (
                <div
                  style={{
                    backgroundColor: 'lightgray',
                    color: 'lightgray',
                    borderRadius: '10px',
                  }}
                >
                  <Typography variant="h6" className={classes.title}>
                    Transporter Rating
                  </Typography>
                </div>
              ) : (
                <>
                  <Typography variant="h6" className={classes.title}>
                    Transporter Rating
                  </Typography>
                  {transporterRating !== 0 && (
                    <CheckCircleOutlineIcon
                      style={{ marginLeft: '10px', color: 'green' }}
                      fontSize="small"
                    />
                  )}
                </>
              )}
            </div>

            <div className={classes.flex}>
              <Rating
                onClick={handleTransporterRating}
                ratingValue={transporterRating}
                size={50}
                classNames={classes.stars}
                allowHover={activeRole !== 'finance'}
                readonly={
                  transporterRating !== 0 ||
                  activeRole === 'ops' ||
                  activeRole === 'finance-payable' ||
                  activeRole === 'finance-recievable' ||
                  activeRole === 'finance-recon' ||
                  activeRole === 'transaction-view' ||
                  currentTransactionData?.void
                }
              />
              {activeRole === 'ops' && transporterRating !== 0 ? (
                <Button
                  size="small"
                  className={classes.submitButton}
                  onClick={() => handleReset('transporter')}
                >
                  Reset
                </Button>
              ) : (
                ''
              )}
            </div>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={12} lg={3}>
        <Card className={classes.root}>
          <CardContent>
            <div className={classes.titleBox}>
              {/* <Typography variant="h6" className={classes.title}>
                Trucker Rating
              </Typography>
              <CheckCircleOutlineIcon
                style={{ marginLeft: '10px', color: 'green' }}
                fontSize="small"
              /> */}
              {fetching ? (
                <div
                  style={{
                    backgroundColor: 'lightgray',
                    color: 'lightgray',
                    borderRadius: '10px',
                  }}
                >
                  <Typography variant="h6" className={classes.title}>
                    Trucker Rating
                  </Typography>
                </div>
              ) : (
                <>
                  <Typography variant="h6" className={classes.title}>
                    Trucker Rating
                  </Typography>
                  {truckerRating !== 0 && (
                    <CheckCircleOutlineIcon
                      style={{ marginLeft: '10px', color: 'green' }}
                      fontSize="small"
                    />
                  )}
                </>
              )}
            </div>

            <div className={classes.flex}>
              <Rating
                onClick={handleTruckerRating}
                ratingValue={truckerRating}
                size={50}
                classNames={classes.stars}
                allowHover={activeRole !== 'finance'}
                readonly={
                  truckerRating !== 0 ||
                  activeRole === 'ops' ||
                  activeRole === 'finance-payable' ||
                  activeRole === 'finance-recievable' ||
                  activeRole === 'finance-recon' ||
                  activeRole === 'transaction-view' ||
                  currentTransactionData?.void
                }
              />
              {activeRole === 'ops' && truckerRating !== 0 ? (
                <Button
                  size="small"
                  className={classes.submitButton}
                  onClick={() => handleReset('trucker')}
                >
                  Reset
                </Button>
              ) : (
                ''
              )}
            </div>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}
