import Transactions from 'views/Transactions/FinanceTransactions.jsx';
// @material-ui/icons
import DashboardIcon from '@material-ui/icons/Dashboard';
import { LocalShipping, TransferWithinAStation } from '@material-ui/icons';
import Truckers from 'views/Truckers/Truckers.jsx';
import Transporters from 'views/Transporters/Transporters.jsx';

const financePayableDashRoutes = [
  {
    path: '/finance-payable/transactions',
    name: 'Transactions',
    icon: DashboardIcon,
    component: Transactions,
  },
  {
    path: '/finance-payable/truckers',
    name: 'View Truckers',
    icon: LocalShipping,
    component: Truckers,
  },
  {
    path: '/finance-payable/transporters',
    name: 'View Transporters',
    icon: TransferWithinAStation,
    component: Transporters,
  },
];
export default financePayableDashRoutes;
