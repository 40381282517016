import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// @material-ui/Icons

// core components

import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CustomTabs from 'components/CustomTabs/CustomTabs.jsx';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TransactionStyle from 'assets/jss/material-dashboard-pro-react/views/TransactionStyle';
import AthHistory from './PayableAthHistory';
import BthHistory from './PayableBthHistory';
import ReimbursementHistory from './PayableReimbursementHistory';
import ExceptionHistory from './PayableExceptionHistory';

import { switchActivePage } from '../../../reducers/transactions';
import apiCaller from '../../../utils/apiCallerOutstanding';
import firebase from '../../../config/config';
import formatDate from '../../../utils/convertDateFormat';
import currencyFormat from '../../../utils/toIndianCurrencyString';

class PayoutsHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bthScheduled: false,
      bthScheduledAmount: '',
      bthScheduledDate: '',
    };
    this.hideDetailedForm = this.hideDetailedForm.bind(this);
  }

  componentDidMount() {
    this.bthScheduledAmountStatus();
  }

  // eslint-disable-next-line react/sort-comp
  hideDetailedForm() {
    // eslint-disable-next-line react/destructuring-assignment
    this.props.switchActivePage(false);
  }

  /**
   * Will return the Tabs ATH and BTH
   * @function
   */
  // eslint-disable-next-line class-methods-use-this
  getTabs() {
    const { currentTransactionData } = this.props;
    return (
      <CustomTabs
        headerColor="#fff"
        tabs={[
          {
            tabName: 'ATH',
            tabContent: (
              <AthHistory currentTransactionData={currentTransactionData} />
            ),
          },
          {
            tabName: 'BTH',
            tabContent: (
              <BthHistory currentTransactionData={currentTransactionData} />
            ),
          },
          {
            tabName: 'Exception',
            tabContent: (
              <ExceptionHistory
                currentTransactionData={currentTransactionData}
              />
            ),
          },
          {
            tabName: 'Reimbursement',
            tabContent: (
              <ReimbursementHistory
                currentTransactionData={currentTransactionData}
              />
            ),
          },
        ]}
      />
    );
  }

  async bthScheduledAmountStatus() {
    const { currentTransactionData } = this.props;

    const userDetails = firebase.auth().currentUser;
    const { Aa: token } = userDetails;

    const endPoint = `pipe/payment-schedule-detail?type=bth_payable&trip_id=${currentTransactionData.transactionId}`;

    try {
      const response = await apiCaller(
        endPoint,
        {},
        `Bearer ${token}`,
        'nh-base',
        'get'
      );
      const data = response?.data;
      this.setState({
        bthScheduled: data.scheduled,
        bthScheduledAmount: data.scheduled_bth_amount,
        bthScheduledDate: data.date,
      });
    } catch (e) {
      console.error('Error', e);
    }
  }

  render() {
    const { classes, currentPayableAmount } = this.props;
    const { bthScheduled, bthScheduledAmount, bthScheduledDate } = this.state;
    return (
      <Card>
        <CardHeader
          className={`${classes.cardHeader}`}
          style={{
            marginBottom: '1px',
            paddingBottom: '1px',
            paddingTop: '1px',
          }}
        >
          <h3 className={classes.cardTitle}>
            Payable:{' '}
            <i
              className="fa fa-inr"
              style={{ fontSize: '22px' }}
              aria-hidden="true"
            />{' '}
            {Intl.NumberFormat('en-IN').format(currentPayableAmount)}
            <small />
          </h3>
          {bthScheduled && (
            <p
              style={{
                color: '#163BAC',
                margin: '5px 0px 0px 0px',
                fontSize: '16px',
              }}
            >
              {currencyFormat(bthScheduledAmount)} BTH is scheduled for{' '}
              {formatDate(bthScheduledDate)}
            </p>
          )}
        </CardHeader>
        <CardBody
          style={{
            marginBottom: '1px',
            paddingBottom: '1px',
            paddingTop: '1px',
          }}
        >
          {this.getTabs()}
        </CardBody>
      </Card>
    );
  }
}

PayoutsHistory.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  isDetailedPage: state.transactions.isDetailedPage,
  // currentTransactionData: state.transactions.currentTransactionData,
  transactions: state.transactions.transactions,
  payableTransactions: state.transactions.payableTransactions,
  currentPayableAmount: state.transactions.currentPayableAmount,
  activeRole: state.main.activeRole,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      switchActivePage,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(TransactionStyle)(PayoutsHistory));
