import React from 'react';
import Grid from '@material-ui/core/Grid';
import CategorySelect from './CategorySelect';
import NumberOfLocationsInput from './NumberOfLocationsInput';
import CreditLimitInputField from './CreditLimitInputField';
import AdvancePaymentTerms from './AdvancePaymentTerms';
import BalancePaymentTerms from './BalancePaymentTerms';
import OnAccount from './OnAccount';

const TransporterNewFieldsView = ({ activeRole, selectedTransporterData }) => (
  <div style={{ marginTop: '30px', marginBottom: '30px' }}>
    <Grid
      container
      spacing={5}
      direction="row"
      // justifyContent="space-evenly"
      flexWrap="wrap"
      alignItems="flex-end"
    >
      {activeRole !== 'finance' &&
        activeRole !== 'finance-payable' &&
        activeRole !== 'finance-recievable' &&
        activeRole !== 'finance-recon' && (
          <>
            <Grid item xs={12} sm={6} lg={4}>
              <CategorySelect
                activeRole={activeRole}
                selectedTransporterData={selectedTransporterData}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <NumberOfLocationsInput
                activeRole={activeRole}
                selectedTransporterData={selectedTransporterData}
              />
            </Grid>
          </>
        )}
      <Grid item xs={12} sm={6} lg={4}>
        <CreditLimitInputField
          activeRole={activeRole}
          selectedTransporterData={selectedTransporterData}
        />
      </Grid>
      <Grid item xs={12} lg={4}>
        <AdvancePaymentTerms
          activeRole={activeRole}
          selectedTransporterData={selectedTransporterData}
        />
      </Grid>
      <Grid item xs={12} lg={4}>
        <BalancePaymentTerms
          activeRole={activeRole}
          selectedTransporterData={selectedTransporterData}
        />
      </Grid>
      <Grid item xs={12} lg={4}>
        <OnAccount
          activeRole={activeRole}
          selectedTransporterData={selectedTransporterData}
        />
      </Grid>
    </Grid>
  </div>
);

export default TransporterNewFieldsView;
