/* eslint-disable no-prototype-builtins */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/sort-comp */
import React from 'react';
import PropTypes from 'prop-types';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// @material-ui/Icons
import NoteAdd from '@material-ui/icons/NoteAdd';
// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import 'react-datepicker/dist/react-datepicker.css';
import Table from 'components/Table/Table.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import SweetAlert from 'react-bootstrap-sweetalert';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import TransactionStyle from 'assets/jss/material-dashboard-pro-react/views/TransactionStyle';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Slide,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import firebase from '../../../config/config';

import {
  switchActivePage,
  setCurrentTransaction,
  setPayableBthTransactions,
} from '../../../reducers/transactions';
import apiCaller from '../../../utils/apiCallerOutstanding';
import formatDate from '../../../utils/convertDateFormat';
import currencyFormat from '../../../utils/toIndianCurrencyString';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" {...props} ref={ref} />
));

const db = firebase.firestore();
const auditLog = firebase.functions().httpsCallable('utilities-auditLog');
const submitAthPayable = firebase
  .functions()
  .httpsCallable('payable-submitAthPayable');

const moment = require('moment');

class BthHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      payableTransactions: [
        {
          color: 'info',
          data: ['28-09-2019', '10,000', 'message is'],
        },
      ],
      amount: '',
      amountState: '',
      message: '',
      messageState: '',
      date: moment().format('MMM Do YY'),
      isBthForm: false,
      tc: false,
      isOtpNotification: false,
      isLoading: false,
      startDate: moment(),
      alert: '',
      transactionTypes: ['IMPS', 'NEFT'],
      currentTransactionType: '',
      classicModal: false,
      otp: '',
      responseData: {},
      isLoadingOtp: false,
      isLocked: false,
      payableId: '',
      otpError: '',
      isLoadingCancel: false,
      errorAlert: false,
      amountErrorMessage: '',
      showPanMessage: false,
      errMessage: '',
      isTruckerBlacklisted: false,
      bthModal: false,
      confirmationDialog: false,
      bthScheduled: false,
      bthScheduledAmount: '',
      bthUpdatedScheduledAmount: '',
      bthScheduledDate: '',
      selectedAction: '',
    };
    this.hideDetailedForm = this.hideDetailedForm.bind(this);
    this.showBthForm = this.showBthForm.bind(this);
    this.clearForm = this.clearForm.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onCancelAlert = this.onCancelAlert.bind(this);
    this.handleType = this.handleType.bind(this);
    this.setSelectedAction = this.setSelectedAction.bind(this);
    this.submitBthPayable = this.submitBthPayable.bind(this);
  }

  componentDidMount() {
    const { currentTransactionData, activeRole } = this.props;
    if (currentTransactionData.isLocked && activeRole === 'finance') {
      const responseData = {};
      const payableRef = db
        .collection('Transactions')
        .doc(currentTransactionData.transactionId)
        .collection('Payable')
        .doc(currentTransactionData.payableId);
      payableRef.get().then(payableDoc => {
        if (payableDoc.exists && payableDoc.data().type !== 'manual') {
          responseData.amount = payableDoc.data().amount;
          responseData.message = payableDoc.data().message;
          responseData.payableId = payableDoc.data().payableId;
          responseData.transactionId = currentTransactionData.transactionId;
          responseData.truckerId = currentTransactionData.truckerId;
          this.setState({
            responseData,
            isLocked: true,
          });
        }
      });
    }
    const bthRef = db
      .collection('Transactions')
      .doc(currentTransactionData.transactionId)
      .collection('Payable')
      .where('type', '==', 'BTH')
      .where('status', '==', 'success');
    bthRef.orderBy('createdAt', 'asc').onSnapshot(bthRes => {
      const bthTransactions = [];
      bthRes.forEach(element => {
        if (
          !currentTransactionData.isEditedTransaction &&
          currentTransactionData.status === 'approved' &&
          !element.data().void
        ) {
          bthTransactions.push({
            color: 'info',
            data: [
              // element.data().date,
              moment(element.data().createdAt.toDate()).format('DD/MM/YY'),
              Intl.NumberFormat('en-IN').format(element.data().amount),
              element.data().message,
            ],
          });
        } else if (element.data().void) {
          bthTransactions.push({
            color: 'info',
            data: [
              // element.data().date,
              moment(element.data().createdAt.toDate()).format('DD/MM/YY'),
              Intl.NumberFormat('en-IN').format(element.data().amount),
              element.data().message,
              ' ',
            ],
            void: element.data().void,
          });
        }
      });
      // eslint-disable-next-line react/destructuring-assignment
      this.props.setPayableBthTransactions(bthTransactions);
    });
    this.handleGetTrucker(currentTransactionData);
  }

  async handleGetTrucker(currentTransactionData) {
    const { truckerId } = currentTransactionData;

    const userDetails = firebase.auth().currentUser;

    const { Aa } = userDetails;
    const token = Aa;
    try {
      const endPoint = `pipe/office-user-validation?id=${truckerId}&type=blacklist&source=trucker`;
      const response = await apiCaller(
        endPoint,
        {},
        `Bearer ${token}`,
        'nh-base',
        'get'
      );
      const data = response?.data;

      if (data.blacklist === true) {
        this.setState({ isTruckerBlacklisted: true });
      } else {
        this.setState({ isTruckerBlacklisted: false });
      }
    } catch (e) {
      console.error(e);
    }
  }

  // eslint-disable-next-line react/sort-comp
  handleClickOpen(modal) {
    const x = [];
    x[modal] = true;
    this.setState(x);
  }

  handleClose(modal) {
    const x = [];
    x[modal] = false;
    this.setState(x);
  }

  onCancelAlert() {
    this.setState({
      alert: null,
    });
  }

  onConfirmAlert() {
    const { classes } = this.props;
    this.setState({
      alert: null,
    });
    setTimeout(() => {
      this.setState({
        alert: (
          <SweetAlert
            success
            style={{ display: 'block', marginTop: '-100px' }}
            title="Please Wait........"
            showConfirm={false}
            confirmBtnCssClass={`${classes.button} ${classes.success}`}
          />
        ),
      });
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }, 0);
  }

  hideDetailedForm() {
    // eslint-disable-next-line react/destructuring-assignment
    this.props.switchActivePage(false);
  }

  /**
   * will return the notification snackbar
   * @param {string} place
   */
  showNotification(place) {
    // eslint-disable-next-line react/destructuring-assignment
    if (!this.state[place]) {
      const x = [];
      x[place] = true;
      this.setState(x);
      setTimeout(() => {
        x[place] = false;
        this.setState(x);
      }, 5000);
    }
  }

  handleChange(date) {
    this.setState({
      startDate: date,
      date: moment(date).format('MMM Do YY'),
    });
  }

  // function that verifies if value contains only numbers
  verifyNumber(value) {
    const numberRex = new RegExp('^[0-9]+$');
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  }

  verifyOtp(value) {
    const numberRex = new RegExp('^[0-9]+$');
    if (numberRex.test(value) && value.toString().length > 5) {
      return true;
    }
    return false;
  }

  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }

  // function that verifies if a string has a given length or not
  verifyNarration(value) {
    // const emailRex = /[^\s@]+@[^\s@]+\.[^\s@]+/;
    const numberRex = new RegExp('^[A-Za-z0-9_ ]+$');
    if (
      numberRex.test(value) &&
      value.toString().length > 3 &&
      value.toString().length < 30
    ) {
      return true;
    }
    return false;
  }

  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case 'number':
        if (this.verifyNumber(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'length':
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'otp':
        if (this.verifyOtp(event.target.value, stateNameEqualTo)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'narration':
        if (this.verifyNarration(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      default:
        this.setState({
          [`${stateName}State`]: 'success',
          [stateName]: event.target.value,
        });
        break;
    }
  }

  /**
   * Will Activate Form for Adding New Payable Transaction
   * @function
   */
  async showBthForm() {
    const { currentTransactionData, classes } = this.props;
    this.setState({
      isLoading: true,
    });
    this.handleClickOpen('bthModal');
    const userDetails = firebase.auth().currentUser;
    const { Aa: token } = userDetails;

    const endPoint = `pipe/payment-schedule-detail?type=bth_payable&trip_id=${currentTransactionData.transactionId}`;

    try {
      const response = await apiCaller(
        endPoint,
        {},
        `Bearer ${token}`,
        'nh-base',
        'get'
      );
      const data = response?.data;
      this.setState({
        bthScheduled: data.scheduled,
        bthScheduledAmount: data.scheduled_bth_amount,
        bthScheduledDate: data.date,
        bthUpdatedScheduledAmount: data.pending_bth_amount,
      });
      this.setState({
        isLoading: false,
      });
    } catch (e) {
      console.error('Error', e);
      this.setState({
        isLoading: false,
        bthModal: false,
        alert: (
          <SweetAlert
            error
            style={{ display: 'block', marginTop: '-100px' }}
            onConfirm={() => this.onCancelAlert()}
            onCancel={() => this.onCancelAlert()}
            confirmBtnCssClass={`${classes.button} ${classes.success}`}
          >
            <p>Failed to Load Payment Scheduling details</p>
          </SweetAlert>
        ),
      });
    }
  }

  setSelectedAction(event) {
    this.setState({ selectedAction: event.target.value });
  }

  getForm() {
    const { classes } = this.props;
    const {
      bthModal,
      bthScheduled,
      bthScheduledAmount,
      bthUpdatedScheduledAmount,
      bthScheduledDate,
      amount,
      amountState,
      amountErrorMessage,
      message,
      messageState,
      isLoading,
      selectedAction,
      confirmationDialog,
    } = this.state;

    return (
      <>
        <Dialog
          open={bthModal}
          TransitionComponent={Transition}
          keepMounted
          fullWidth
          maxWidth="sm"
          onClose={() => this.handleClose('bthModal')}
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <div className={classes.modalHeaderContent}>
              <h5 className={classes.modalTitle} style={{ fontWeight: 500 }}>
                {bthScheduled ? 'Change In BTH Schedule' : 'Add More'}
              </h5>
            </div>
          </DialogTitle>

          <DialogContent className={classes.modalBody}>
            {isLoading ? (
              <CircularProgress />
            ) : (
              <div>
                {bthScheduled && (
                  <div>
                    <div style={{ marginBottom: '10px' }}>
                      <text
                        style={{
                          color: '#4B5563',
                          fontSize: '16px',
                          fontWeight: 500,
                        }}
                      >
                        {currencyFormat(bthScheduledAmount)} is scheduled for{' '}
                        {formatDate(bthScheduledDate)}
                      </text>
                    </div>
                    <FormControl component="fieldset">
                      <RadioGroup
                        aria-label="bth-action"
                        name="bth-action"
                        value={selectedAction}
                        onChange={this.setSelectedAction}
                      >
                        <FormControlLabel
                          value="on_hold"
                          control={<Radio color="primary" />}
                          label={`Put the scheduled payment (${currencyFormat(
                            bthScheduledAmount
                          )}) on hold and proceed manually`}
                          style={{
                            color: '#4B5563',
                          }}
                        />
                        <FormControlLabel
                          value="schedule_balance_difference"
                          control={<Radio color="primary" />}
                          label="Make partial payment and auto-schedule remaining amount"
                          style={{
                            color: '#4B5563',
                          }}
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                )}

                {(!bthScheduled || selectedAction) && (
                  <>
                    <div style={{ display: 'flex', gap: '20px' }}>
                      <CustomInput
                        labelText="Amount"
                        id="amount"
                        formControlProps={{ fullWidth: true }}
                        inputProps={{
                          value: amount,
                          onChange: event =>
                            this.change(event, 'amount', 'number'),
                        }}
                        success={amountState === 'success'}
                        error={amountState === 'error'}
                      />
                      <CustomInput
                        labelText="Narration"
                        id="message"
                        formControlProps={{ fullWidth: true }}
                        inputProps={{
                          value: message,
                          onChange: event =>
                            this.change(event, 'message', 'narration'),
                          type: 'length',
                        }}
                        success={messageState === 'success'}
                        error={messageState === 'error'}
                      />
                    </div>
                    <p style={{ color: 'red' }}>{amountErrorMessage}</p>
                  </>
                )}

                {selectedAction === 'schedule_balance_difference' &&
                  amount > 0 && (
                    <div
                      style={{
                        marginTop: '10px',
                        backgroundColor: '#E7F1FF',
                        display: 'flex',
                        gap: '10px',
                      }}
                    >
                      <InfoIcon fontSize="small" style={{ color: '#287FFB' }} />
                      <text style={{ color: '#287FFB', fontWeight: 500 }}>
                        Remaining BTH amount{' '}
                        {currencyFormat(bthUpdatedScheduledAmount - amount)}{' '}
                        will be auto-scheduled for{' '}
                        {formatDate(bthScheduledDate)}.
                      </text>
                    </div>
                  )}
              </div>
            )}
          </DialogContent>
          <DialogActions className={classes.modalFooter}>
            <Button onClick={() => this.handleClose('bthModal')}>Cancel</Button>
            <Button
              color="success"
              onClick={() => this.handleClickOpen('confirmationDialog')}
              disabled={
                !(amountState === 'success' && messageState === 'success') ||
                isLoading
              }
            >
              Proceed
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={confirmationDialog} fullWidth maxWidth="xs">
          <DialogTitle>Are You Sure?</DialogTitle>
          <DialogContent>
            <p>Are you sure to proceed with the change?</p>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.handleClose('confirmationDialog')}>
              Cancel
            </Button>
            <Button onClick={() => this.submitBthPayable()} color="success">
              Yes, Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }

  /**
   * Will return the Table Component
   * @function
   */
  getTable() {
    const { classes, bthTransactions } = this.props;
    return (
      <Card
        style={{ marginTop: '2px', paddingBottom: '1px', marginBottom: '5px' }}
      >
        {bthTransactions.length > 0 ? (
          <CardBody className={classes.customCardContentClass}>
            <Table
              hover
              tableHead={['Date', 'Amount', 'Narration']}
              tableData={bthTransactions}
            />
          </CardBody>
        ) : (
          <p
            className={classes.textCenter}
            style={{ marginTop: '10px', marginBottom: '10px' }}
          >
            No Transactions Available
          </p>
        )}
      </Card>
    );
  }

  getPanWarning() {
    const { classes } = this.props;
    return (
      <Card
        style={{ marginTop: '2px', paddingBottom: '1px', marginBottom: '5px' }}
      >
        <p
          className={classes.textCenter}
          style={{ color: '#D32F2F', marginTop: '10px', marginBottom: '10px' }}
        >
          PAN is invalid. Please update a vaild PAN.
        </p>
      </Card>
    );
  }

  // validates all required fields and returns a promise
  validateAllFields() {
    const { currentPayableAmount } = this.props;
    const { amount, message } = this.state;
    const { currentTransactionData } = this.props;
    let { limitPayable } = currentTransactionData;
    if (limitPayable === undefined) {
      limitPayable = false;
    }
    let error = false;
    return new Promise((resolve, reject) => {
      // Verify  Amount
      if (this.verifyNumber(amount)) {
        this.setState({
          amountState: 'success',
        });
      } else {
        this.setState({ amountState: 'error' });
        error = true;
      }

      if (limitPayable) {
        if (Number(amount) > 0 && Number(amount) <= 99000) {
          this.setState({
            amountState: 'success',
            amountErrorMessage: '',
          });
        } else {
          this.setState({
            amountState: 'error',
            amountErrorMessage: 'Please Enter Valid Amount',
          });
          error = true;
        }
      } else if (Number(amount) > 0) {
        this.setState({
          amountState: 'success',
          amountErrorMessage: '',
        });
      } else {
        this.setState({
          amountState: 'error',
          amountErrorMessage: 'Please Enter Valid Amount',
        });
        error = true;
      }

      // Verify whether the amount is less the currentReceivableAmount
      if (parseInt(amount, 10) > parseInt(currentPayableAmount, 10)) {
        this.setState({
          amountState: 'error',
          amountErrorMessage: 'BTH Exceeds Total Payable',
        });
        error = true;
      }

      // Verify  message
      if (this.verifyNarration(message)) {
        this.setState({
          messageState: 'success',
        });
      } else {
        this.setState({ messageState: 'error' });
        error = true;
      }

      if (!error) {
        resolve(true);
      } else {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject('validation Failed');
      }
    });
  }

  handleType = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  /**
   * Will Submit the Payable history to Database
   * @function
   */

  async submitBthPayable() {
    try {
      await this.validateAllFields();
    } catch (error) {
      this.setState({
        confirmationDialog: false,
      });
      return;
    }
    const {
      currentTransactionData,
      classes,
      activeRole,
      systemDetails,
    } = this.props;
    const currentRole =
      activeRole === 'finance-payable' ? 'financePayable' : activeRole;
    const currentActiveRole = `is${currentRole
      .charAt(0)
      .toUpperCase()}${currentRole.slice(1)}`;
    const {
      selectedAction,
      amount,
      message,
      bthScheduledDate,
      bthUpdatedScheduledAmount,
    } = this.state;

    this.setState({
      confirmationDialog: false,
      isLoading: true,
    });
    const userDetails = firebase.auth().currentUser;
    const { Aa: token } = userDetails;

    const endPoint = `pipe/payment-schedule-detail`;
    const body = {
      role: currentActiveRole,
      type: selectedAction || 'no_schedule',
      transaction_id: currentTransactionData.transactionId,
      amount,
      narration: message,
    };

    const functionBody = {
      amount,
      message,
      type: 'BTH',
      truckerId: currentTransactionData?.truckerId,
      transactionId: currentTransactionData.transactionId,
      transactionSerial: currentTransactionData.transactionSerial,
      createdBy: userDetails.uid,
      createdByName: userDetails.displayName,
    };
    if (selectedAction === 'on_hold' || !selectedAction) {
      try {
        await submitAthPayable(functionBody);
      } catch (error) {
        this.setState({
          isLoading: false,
          alert: (
            <SweetAlert
              error
              style={{ display: 'block', marginTop: '-100px' }}
              title="BTH Payment Failed"
              onConfirm={() => this.onCancelAlert()}
              confirmBtnCssClass={`${classes.button} ${classes.success}`}
            />
          ),
        });
        return;
      }
    }
    try {
      const response = await apiCaller(
        endPoint,
        body,
        `Bearer ${token}`,
        'nh-base',
        'post'
      );
      const data = response?.data;
      this.handleClose('bthModal');
      if (data?.Sub_status === 1) {
        this.setState({
          alert: (
            <SweetAlert
              success
              style={{ display: 'block', marginTop: '-250px' }}
              title="BTH added and remaining BTH auto-scheduled successfully!"
              onConfirm={() => this.onConfirmAlert()}
              onCancel={() => this.onConfirmAlert()}
              confirmBtnCssClass={`${classes.button} ${classes.success}`}
            >
              <p>BTH amount {currencyFormat(amount)} added successfully!</p>
              <p>
                Remaining BTH amount{' '}
                {currencyFormat(bthUpdatedScheduledAmount - amount)}{' '}
                auto-scheduled for {formatDate(bthScheduledDate)} successful!
              </p>
            </SweetAlert>
          ),
        });
      } else if (data?.Sub_status === 0) {
        this.setState({
          alert: (
            <SweetAlert
              success
              style={{ display: 'block', marginTop: '-250px' }}
              title="BTH added and remaining BTH auto-scheduled successfully!"
              onConfirm={() => this.onConfirmAlert()}
              onCancel={() => this.onConfirmAlert()}
              confirmBtnCssClass={`${classes.button} ${classes.success}`}
            >
              <p>BTH amount {currencyFormat(amount)} added successfully!</p>
              <p color="red">
                Remaining BTH amount{' '}
                {currencyFormat(bthUpdatedScheduledAmount - amount)} could not
                be auto-scheduled.
              </p>
            </SweetAlert>
          ),
        });
      } else {
        this.setState({
          alert: (
            <SweetAlert
              success
              style={{ display: 'block', marginTop: '-100px' }}
              title="BTH Added successfully"
              onConfirm={() => this.onConfirmAlert()}
              onCancel={() => this.onConfirmAlert()}
              confirmBtnCssClass={`${classes.button} ${classes.success}`}
            />
          ),
        });
      }
      const auditBody = {
        collection: 'Payable',
        updatedBy: userDetails.uid,
        systemDetails,
        type: 'Finance Entry',
        message: `${userDetails.email} added Payable Entry (Payout) for Rs.${amount} BTH for Transaction ${currentTransactionData.transactionSerial}`,
      };
      auditLog(auditBody);
      this.setState({
        isLoading: false,
      });
    } catch (e) {
      console.error('Error', e);

      if (e.response && e.response.status === 409) {
        this.setState({
          isLoading: false,
          amountState: 'error',
          amountErrorMessage: 'PAN is invalid. Please update a valid PAN.',
        });
      } else {
        this.setState({
          isLoading: false,
          alert: (
            <SweetAlert
              error
              style={{ display: 'block', marginTop: '-100px' }}
              title="BTH Payment Added But Scheduling Failed"
              onConfirm={() => this.onCancelAlert()}
              onCancel={() => this.onCancelAlert()}
              confirmBtnCssClass={`${classes.button} ${classes.success}`}
            />
          ),
        });
        const errorBody = {
          message: `${userDetails.email} tried to add Payable Entry Rs.${amount} BTH for Transaction ${currentTransactionData.transactionSerial} but FAILED`,
          status: 'fail',
          error: e,
          updatedBy: userDetails.uid,
          errorMessage: e.message,
          collection: 'Payable',
          systemDetails,
        };
        auditLog(errorBody);
      }
    }
  }

  /**
   * will clear the form
   * @function
   */
  clearForm() {
    this.showBthForm();
    this.setState({
      amount: '',
      message: '',
      amountState: '',
      messageState: '',
      isLoading: false,
      isLoadingOtp: false,
      responseData: {},
      currentTransactionType: '',
      otp: '',
      otpState: '',
      isLoadingCancel: false,
    });
  }

  render() {
    const { classes, activeRole, currentTransactionData } = this.props;
    const { alert, isTruckerBlacklisted } = this.state;

    return (
      <GridContainer>
        {alert}
        <GridItem xs={12} sm={12} md={12}>
          {this.getTable()}
          {this?.state?.showPanMessage && this.getPanWarning()}
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              {(activeRole === 'finance' || activeRole === 'finance-payable') &&
              !currentTransactionData.void ? (
                <Button
                  color="info"
                  className={classes.updateProfileButton}
                  onClick={() => this.showBthForm()}
                >
                  <NoteAdd />
                  Add More
                </Button>
              ) : null}
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem xs={12} sm={12} md={12} />
        {this.getForm()}
      </GridContainer>
    );
  }
}

BthHistory.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  isDetailedPage: state.transactions.isDetailedPage,
  // currentTransactionData: state.transactions.currentTransactionData,
  transactions: state.transactions.transactions,
  payableTransactions: state.transactions.payableTransactions,
  bthTransactions: state.transactions.payableBthTransactions,
  currentPayableAmount: state.transactions.currentPayableAmount,
  activeRole: state.main.activeRole,
  userDetails: state.main.userDetails,
  systemDetails: state.main.systemDetails,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      switchActivePage,
      setCurrentTransaction,
      setPayableBthTransactions,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(TransactionStyle)(BthHistory));
