/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Skeleton } from '@material-ui/lab';
import groupcheck from 'assets/icons/Group.svg';
import singlecheck from 'assets/icons/Single Check.svg';
import { Typography } from '@material-ui/core';
import apiCaller from '../../../../utils/apiCallerOutstanding';
import mapRole from '../../../../utils/roleMapping';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableHead: { background: 'black', color: 'white' },
  tableLabel: {
    height: '60px',
    color: 'white',
  },
});

function createData(txn, city, payable, freight, date, podC, podS, tripId) {
  return { txn, city, payable, freight, date, podC, podS, tripId };
}

export default function BasicTable({ token, truckerID, activeRole }) {
  const [tripData, setTripData] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const classes = useStyles();
  const rows = tripData?.map(data =>
    createData(
      data?.trip_no,
      `${data?.from_city} -> ${data?.to_city}`,
      data?.payable,
      data?.freight,
      data?.date,
      data?.pod_collected_date,
      data?.pod_submitted_date,
      data?.trip_id
    )
  );
  const role = mapRole(activeRole);
  const currentActiveRole = `is${role.charAt(0).toUpperCase()}${role.slice(1)}`;

  async function fecthLastTrips() {
    setIsFetching(true);
    const endPoint = `pipe/trip-info?type=trucker&org_id=${truckerID}&detailed_type=trip_summary&role=${currentActiveRole}`;
    try {
      const response = await apiCaller(
        endPoint,
        {},
        `Bearer ${token}`,
        'nh-base',
        'get'
      );
      setTripData(response?.data?.Data);
      setIsFetching(false);
      //   setPendingPayable(response?.data?.pending_payable);
    } catch (e) {
      setIsFetching(false);
    }
  }

  const redirectToDetailedTrsnactions = transactionId => {
    const url = `/${activeRole}/transactions/view/${transactionId}`;

    // Open the URL in a new tab
    window.open(url, '_blank');
  };
  useEffect(() => {
    fecthLastTrips();
  }, []);
  return (
    <div
      style={{
        padding: '20px 10px',
        borderBottom: '1px solid lightgray',
      }}
    >
      <Typography variant="body2" style={{ fontWeight: 'bold' }} gutterBottom>
        Last 10 Trips
      </Typography>
      <TableContainer component={Paper}>
        {isFetching ? (
          <>
            <Skeleton variant="text" />
            <Skeleton variant="rect" height={118} />
          </>
        ) : (
          <Table className={classes.table} aria-label="simple table">
            <TableHead className={classes.tableHead}>
              <TableRow>
                <TableCell className={classes.tableLabel}>TXN</TableCell>
                <TableCell
                  align="left"
                  className={classes.tableLabel}
                  style={{ width: '25%' }}
                >
                  CITY
                </TableCell>
                <TableCell align="left" className={classes.tableLabel}>
                  PAYABLE
                </TableCell>
                <TableCell align="left" className={classes.tableLabel}>
                  FREIGHT
                </TableCell>
                <TableCell align="left" className={classes.tableLabel}>
                  DATE
                </TableCell>
                <TableCell align="left" className={classes.tableLabel}>
                  POD (C)
                </TableCell>
                <TableCell align="left" className={classes.tableLabel}>
                  POD (S)
                </TableCell>
                <TableCell
                  colSpan={1}
                  align="left"
                  className={classes.tableLabel}
                />{' '}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map(row => (
                <TableRow key={row?.txn} style={{ cursor: 'pointer' }}>
                  <TableCell
                    align="left"
                    scope="row"
                    onClick={() => redirectToDetailedTrsnactions(row?.tripId)}
                  >
                    {row?.txn}
                  </TableCell>
                  <TableCell align="left" component="th" scope="row">
                    {row?.city}
                  </TableCell>
                  <TableCell align="left">{row?.payable}</TableCell>
                  <TableCell align="left">{row?.freight}</TableCell>
                  <TableCell align="left">{row?.date}</TableCell>
                  <TableCell align="left">{row?.podC}</TableCell>
                  <TableCell align="left">{row?.podS}</TableCell>
                  <TableCell align="left">
                    {row?.podC && row?.podS ? (
                      <img src={groupcheck} alt="icon" />
                    ) : !row?.podC && row?.podS ? (
                      <img src={singlecheck} alt="icon" />
                    ) : row?.podC && !row?.podS ? (
                      <img src={singlecheck} alt="icon" />
                    ) : null}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
    </div>
  );
}
