/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable class-methods-use-this */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// @material-ui/icons

// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import CircularProgress from '@material-ui/core/CircularProgress';
import purple from '@material-ui/core/colors/purple';
// import Cookies from "universal-cookie";
import InfoArea from 'components/InfoArea/InfoArea.jsx';
import Info from '@material-ui/icons/Info';
import Card from 'components/Card/Card.jsx';

import registerPageStyle from 'assets/jss/material-dashboard-pro-react/views/registerPageStyle';
import firebase from '../../config/config';
import { setUserDetails } from '../../reducers/main';

// const cookies = new Cookies();
class InitialPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isNotAuth: false,
    };
    this.checkAuth = this.checkAuth.bind(this);
  }

  componentDidMount() {
    // Check weather the user is Authenticated
    this.checkAuth();
  }

  /**
   * Check weather the user is Authenticated
   * @function
   */
  checkAuth() {
    this.setState({
      isLoading: true,
    });
    const db = firebase.firestore();
    const usersRef = db.collection('Users');
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        // Check if user already exists
        // this.setState({
        //   uid: user.uid,
        // });
        usersRef
          .doc(user.uid)
          .get()
          .then(
            userDoc => {
              if (userDoc.exists) {
                this.props.setUserDetails(userDoc.data(), user.uid);
                if (userDoc.data().isAdmin === true) {
                  //  this.props.history.push("/admin/dashboard");
                  this.props.history.push('/ops/dashboard');
                } else if (userDoc.data().isFinance === true) {
                  this.props.history.push('/finance/dashboard');
                } else if (userDoc.data().isSales === true) {
                  this.props.history.push('/sales/dashboard');
                } else if (userDoc.data().isTraffic === true) {
                  this.props.history.push('/traffic/dashboard');
                } else if (userDoc.data().isOps === true) {
                  this.props.history.push('/ops/dashboard');
                } else if (userDoc.data().isBranchOps === true) {
                  this.props.history.push('/branch-ops/dashboard');
                } else if (userDoc.data().isPremiumTransporterAdmin === true) {
                  this.props.history.push('/transporters/dashboard');
                } else if (userDoc.data().isFinanceRecievable === true) {
                  this.props.history.push('/finance-recievable/transactions');
                } else if (userDoc.data().isFinancePayable === true) {
                  this.props.history.push('/finance-payable/transactions');
                } else if (userDoc.data().isFinanceRecon === true) {
                  this.props.history.push('/finance-recon/transactions');
                } else if (userDoc.data().isTransactionView === true) {
                  this.props.history.push('/transaction-view/transactions');
                } else if (userDoc.data().isTrackingRole === true) {
                  this.props.history.push('/tracking-role/transactions');
                } else if (userDoc.data().isPartnerExec === true) {
                  this.props.history.push('/partner-exec/dashboard');
                } else if (userDoc.data().isPartnerExecManager === true) {
                  this.props.history.push('/partner-exec-manager/truckers');
                } else if (
                  userDoc.data().isPremiumTransporterContact === true
                ) {
                  this.props.history.push('/transporters/dashboard');
                }
                this.setState({ isLoading: false });
              } else {
                this.setState({
                  isNotAuth: true,
                  isLoading: false,
                });
              }
            },
            err => {
              this.setState({
                isNotAuth: true,
                isLoading: false,
              });
            }
          );
      } else {
        // if not logged go to login screen
        this.setState({
          isLoading: false,
        });
        this.props.history.push('/pages/login-page');
      }
    });
  }

  /**
   * Update the User Details in Db
   * @function
   */
  updateExhibitorInUsers(userDetails) {
    // let this = this
    const databaseRef = firebase.database().ref(`Users/${userDetails.uid}/`);
    databaseRef.update(userDetails).then(
      () => {
        // updated successfully
        alert('updated successfully');
        // this.props.history.push('')
      },
      err => {
        // not updated successfully
      }
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <GridContainer justify="center">
          {this.state.isLoading && (
            <GridItem style={{ marginTop: '150px' }}>
              <CircularProgress
                className={classes.progress}
                style={{ color: purple[500] }}
                thickness={7}
              />
            </GridItem>
          )}
          {this.state.isPending && (
            <GridItem style={{ marginTop: '150px' }} xs={12} sm={6} md={6}>
              <Card>
                <InfoArea
                  title="Pending"
                  description="Please Wait for the Approval"
                  icon={Info}
                  iconColor="rose"
                />
              </Card>
            </GridItem>
          )}
          {this.state.isNotAuth && (
            <GridItem style={{ marginTop: '150px' }} xs={12} sm={6} md={6}>
              <Card>
                <InfoArea
                  title="Not Authorized"
                  description="No Access"
                  icon={Info}
                  iconColor="rose"
                />
              </Card>
            </GridItem>
          )}
        </GridContainer>
      </div>
    );
  }
}

InitialPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setUserDetails,
    },
    dispatch
  );

export default connect(
  null,
  mapDispatchToProps
)(withStyles(registerPageStyle)(InitialPage));
