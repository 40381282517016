/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-string-refs */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-multi-comp */
import React from 'react';
import PropTypes from 'prop-types';
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from 'perfect-scrollbar';
import { NavLink } from 'react-router-dom';
import cx from 'classnames';
import { connect } from 'react-redux';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Hidden from '@material-ui/core/Hidden';
import Collapse from '@material-ui/core/Collapse';

// core components
import HeaderLinks from 'components/Header/HeaderLinks.jsx';

import sidebarStyle from 'assets/jss/material-dashboard-pro-react/components/sidebarStyle.jsx';

import male from 'assets/img/man.png';
import female from 'assets/img/girl.png';

let ps;

// We've created this component so we can have a ref to the wrapper of the links that appears in our sidebar.
// This was necessary so that we could initialize PerfectScrollbar on the links.
// There might be something with the Hidden component from material-ui, and we didn't have access to
// the links, and couldn't initialize the plugin.
class SidebarWrapper extends React.Component {
  componentDidMount() {
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(this.refs.sidebarWrapper, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf('Win') > -1) {
      ps.destroy();
    }
  }

  render() {
    const { className, user, headerLinks, links } = this.props;
    return (
      <div className={className} ref="sidebarWrapper">
        {user}
        {headerLinks}
        {links}
      </div>
    );
  }
}

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openAvatar: false,
      openComponents: this.activeRoute('/components'),
      openForms: this.activeRoute('/forms'),
      openTables: this.activeRoute('/tables'),
      openMaps: this.activeRoute('/maps'),
      openPages: this.activeRoute('-page'),
      miniActive: true,
    };
    this.activeRoute.bind(this);
  }

  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1;
  }

  openCollapse(collapse) {
    const st = {};
    st[collapse] = !this.state[collapse];
    this.setState(st);
  }

  render() {
    const {
      classes,
      color,
      logo,
      image,
      logoText,
      routes,
      bgColor,
      rtlActive,
    } = this.props;

    const itemText = `${classes.itemText} ${cx({
      [classes.itemTextMini]: this.props.miniActive && this.state.miniActive,
      [classes.itemTextMiniRTL]:
        rtlActive && this.props.miniActive && this.state.miniActive,
      [classes.itemTextRTL]: rtlActive,
    })}`;
    const collapseItemText = `${classes.collapseItemText} ${cx({
      [classes.collapseItemTextMini]:
        this.props.miniActive && this.state.miniActive,
      [classes.collapseItemTextMiniRTL]:
        rtlActive && this.props.miniActive && this.state.miniActive,
      [classes.collapseItemTextRTL]: rtlActive,
    })}`;
    const caret = `${classes.caret} ${cx({
      [classes.caretRTL]: rtlActive,
    })}`;
    const userWrapperClass = `${classes.user} ${cx({
      [classes.whiteAfter]: bgColor === 'white',
    })}`;

    const collapseItemMini = `${classes.collapseItemMini} ${cx({
      [classes.collapseItemMiniRTL]: rtlActive,
    })}`;
    const photo = `${classes.photo} ${cx({
      [classes.photoRTL]: rtlActive,
    })}`;

    const user = (
      <div className={userWrapperClass}>
        <div className={photo}>
          {this.props.gender === 'female' && (
            <img src={female} className={classes.avatarImg} alt="..." />
          )}
          {this.props.gender === 'male' && (
            <img src={male} className={classes.avatarImg} alt="..." />
          )}
        </div>
        <List className={classes.list}>
          <ListItem className={`${classes.item} ${classes.userItem}`}>
            <NavLink
              to="#"
              className={`${classes.itemLink} ${classes.userCollapseButton}`}
              onClick={() => this.openCollapse('openAvatar')}
            >
              <ListItemText
                primary={rtlActive ? 'تانيا أندرو' : this.props.name}
                secondary={
                  <b
                    className={`${caret} ${classes.userCaret} ${
                      this.state.openAvatar ? classes.caretActive : ''
                    }`}
                  />
                }
                disableTypography
                className={`${itemText} ${classes.userItemText}`}
              />
            </NavLink>
            <Collapse in={this.state.openAvatar} unmountOnExit>
              <List className={`${classes.list} ${classes.collapseList}`}>
                {(this.props.userDetails.isAdmin ||
                  this.props.userDetails.isOps) && (
                  <ListItem className={classes.collapseItem}>
                    <NavLink
                      to="/ops/dashboard"
                      className={`${classes.itemLink} ${classes.userCollapseLinks}`}
                    >
                      <span className={collapseItemMini}>
                        {rtlActive ? 'مع' : 'O'}
                      </span>
                      <ListItemText
                        primary={rtlActive ? 'ملفي' : 'Ops Admin'}
                        disableTypography
                        className={collapseItemText}
                      />
                    </NavLink>
                  </ListItem>
                )}
                {(this.props.userDetails.isAdmin ||
                  this.props.userDetails.isBranchOps) && (
                  <ListItem className={classes.collapseItem}>
                    <NavLink
                      to="/branch-ops/dashboard"
                      className={`${classes.itemLink} ${classes.userCollapseLinks}`}
                    >
                      <span className={collapseItemMini}>
                        {rtlActive ? 'مع' : 'B'}
                      </span>
                      <ListItemText
                        primary={rtlActive ? 'ملفي' : 'Branch Ops'}
                        disableTypography
                        className={collapseItemText}
                      />
                    </NavLink>
                  </ListItem>
                )}
                {(this.props.userDetails.isAdmin ||
                  this.props.userDetails.isFinance) && (
                  <ListItem className={classes.collapseItem}>
                    <NavLink
                      to="/finance/dashboard"
                      className={`${classes.itemLink} ${classes.userCollapseLinks}`}
                    >
                      <span className={collapseItemMini}>
                        {rtlActive ? 'و' : 'F'}
                      </span>
                      <ListItemText
                        primary={rtlActive ? 'إعدادات' : 'Finance'}
                        disableTypography
                        className={collapseItemText}
                      />
                    </NavLink>
                  </ListItem>
                )}
                {(this.props.userDetails.isAdmin ||
                  this.props.userDetails.isSales) && (
                  <ListItem className={classes.collapseItem}>
                    <NavLink
                      to="/sales/dashboard"
                      className={`${classes.itemLink} ${classes.userCollapseLinks}`}
                    >
                      <span className={collapseItemMini}>
                        {rtlActive ? 'و' : 'S'}
                      </span>
                      <ListItemText
                        primary={rtlActive ? 'إعدادات' : 'Sales'}
                        disableTypography
                        className={collapseItemText}
                      />
                    </NavLink>
                  </ListItem>
                )}
                {this.props.userDetails.isCallCenter && (
                  <ListItem className={classes.collapseItem}>
                    <NavLink
                      to="/call_center/trucker-leads"
                      className={`${classes.itemLink} ${classes.userCollapseLinks}`}
                    >
                      <span className={collapseItemMini}>
                        {rtlActive ? 'و' : 'C'}
                      </span>
                      <ListItemText
                        primary={rtlActive ? 'إعدادات' : 'Call Center'}
                        disableTypography
                        className={collapseItemText}
                      />
                    </NavLink>
                  </ListItem>
                )}
                {this.props.userDetails.isFinanceRecievable && (
                  <ListItem className={classes.collapseItem}>
                    <NavLink
                      to="/finance-recievable/transactions"
                      className={`${classes.itemLink} ${classes.userCollapseLinks}`}
                    >
                      <span className={collapseItemMini}>
                        {rtlActive ? 'و' : 'F R'}
                      </span>
                      <ListItemText
                        primary={rtlActive ? 'إعدادات' : 'Finance Recievable'}
                        disableTypography
                        className={collapseItemText}
                      />
                    </NavLink>
                  </ListItem>
                )}
                {this.props.userDetails.isFinancePayable && (
                  <ListItem className={classes.collapseItem}>
                    <NavLink
                      to="/finance-payable/transactions"
                      className={`${classes.itemLink} ${classes.userCollapseLinks}`}
                    >
                      <span className={collapseItemMini}>
                        {rtlActive ? 'و' : 'F P'}
                      </span>
                      <ListItemText
                        primary={rtlActive ? 'إعدادات' : 'Finance Payable'}
                        disableTypography
                        className={collapseItemText}
                      />
                    </NavLink>
                  </ListItem>
                )}
                {this.props.userDetails.isFinanceRecon && (
                  <ListItem className={classes.collapseItem}>
                    <NavLink
                      to="/finance-recon/transactions"
                      className={`${classes.itemLink} ${classes.userCollapseLinks}`}
                    >
                      <span className={collapseItemMini}>
                        {rtlActive ? 'و' : 'F P'}
                      </span>
                      <ListItemText
                        primary={rtlActive ? 'إعدادات' : 'Finance Recon'}
                        disableTypography
                        className={collapseItemText}
                      />
                    </NavLink>
                  </ListItem>
                )}
                {this.props.userDetails.isTransactionView && (
                  <ListItem className={classes.collapseItem}>
                    <NavLink
                      to="/transaction-view/transactions"
                      className={`${classes.itemLink} ${classes.userCollapseLinks}`}
                    >
                      <span className={collapseItemMini}>
                        {rtlActive ? 'و' : 'T V'}
                      </span>
                      <ListItemText
                        primary={rtlActive ? 'إعدادات' : 'Transaction View'}
                        disableTypography
                        className={collapseItemText}
                      />
                    </NavLink>
                  </ListItem>
                )}
                {this.props.userDetails.isTrackingRole && (
                  <ListItem className={classes.collapseItem}>
                    <NavLink
                      to="/tracking-role/transactions"
                      className={`${classes.itemLink} ${classes.userCollapseLinks}`}
                    >
                      <span className={collapseItemMini}>
                        {rtlActive ? 'و' : 'T R'}
                      </span>
                      <ListItemText
                        primary={rtlActive ? 'إعدادات' : 'Tracking Role'}
                        disableTypography
                        className={collapseItemText}
                      />
                    </NavLink>
                  </ListItem>
                )}
                {this.props.userDetails.isPartnerExec && (
                  <ListItem className={classes.collapseItem}>
                    <NavLink
                      to="/partner-exec/dashboard"
                      className={`${classes.itemLink} ${classes.userCollapseLinks}`}
                    >
                      <span className={collapseItemMini}>
                        {rtlActive ? 'و' : 'P E'}
                      </span>
                      <ListItemText
                        primary={rtlActive ? 'إعدادات' : 'Partner Exec'}
                        disableTypography
                        className={collapseItemText}
                      />
                    </NavLink>
                  </ListItem>
                )}
                {this.props.userDetails.isPartnerExecManager && (
                  <ListItem className={classes.collapseItem}>
                    <NavLink
                      to="/partner-exec-manager/truckers"
                      className={`${classes.itemLink} ${classes.userCollapseLinks}`}
                    >
                      <span className={collapseItemMini}>
                        {rtlActive ? 'و' : 'P'}
                      </span>
                      <ListItemText
                        primary={rtlActive ? 'إعدادات' : 'Partner Exec Manager'}
                        disableTypography
                        className={collapseItemText}
                      />
                    </NavLink>
                  </ListItem>
                )}
                {this.props.userDetails.isTraffic && (
                  <ListItem className={classes.collapseItem}>
                    <NavLink
                      to="/traffic/dashboard"
                      className={`${classes.itemLink} ${classes.userCollapseLinks}`}
                    >
                      <span className={collapseItemMini}>
                        {rtlActive ? 'و' : 'T'}
                      </span>
                      <ListItemText
                        primary={rtlActive ? 'إعدادات' : 'Traffic'}
                        disableTypography
                        className={collapseItemText}
                      />
                    </NavLink>
                  </ListItem>
                )}
              </List>
            </Collapse>
          </ListItem>
        </List>
      </div>
    );
    const links = (
      <List className={classes.list}>
        {routes.map((prop, key) => {
          if (prop.redirect) {
            return null;
          }
          if (prop.collapse) {
            const navLinkClasses = `${classes.itemLink} ${cx({
              [` ${classes.collapseActive}`]: this.activeRoute(prop.path),
            })}`;
            const itemText = `${classes.itemText} ${cx({
              [classes.itemTextMini]:
                this.props.miniActive && this.state.miniActive,
              [classes.itemTextMiniRTL]:
                rtlActive && this.props.miniActive && this.state.miniActive,
              [classes.itemTextRTL]: rtlActive,
            })}`;
            const collapseItemText = `${classes.collapseItemText} ${cx({
              [classes.collapseItemTextMini]:
                this.props.miniActive && this.state.miniActive,
              [classes.collapseItemTextMiniRTL]:
                rtlActive && this.props.miniActive && this.state.miniActive,
              [classes.collapseItemTextRTL]: rtlActive,
            })}`;
            const itemIcon = `${classes.itemIcon} ${cx({
              [classes.itemIconRTL]: rtlActive,
            })}`;
            const caret = `${classes.caret} ${cx({
              [classes.caretRTL]: rtlActive,
            })}`;
            return (
              <ListItem key={key} className={classes.item}>
                <NavLink
                  to="#"
                  className={navLinkClasses}
                  onClick={() => this.openCollapse(prop.state)}
                >
                  <ListItemIcon className={itemIcon}>
                    <prop.icon />
                  </ListItemIcon>
                  <ListItemText
                    primary={prop.name}
                    secondary={
                      <b
                        className={`${caret} ${
                          this.state[prop.state] ? classes.caretActive : ''
                        }`}
                      />
                    }
                    disableTypography
                    className={itemText}
                  />
                </NavLink>
                <Collapse in={this.state[prop.state]} unmountOnExit>
                  <List className={`${classes.list} ${classes.collapseList}`}>
                    {prop.views.map((prop, key) => {
                      if (prop.redirect) {
                        return null;
                      }
                      const navLinkClasses = `${classes.collapseItemLink} ${cx({
                        [` ${classes[color]}`]: this.activeRoute(prop.path),
                      })}`;
                      const collapseItemMini = `${
                        classes.collapseItemMini
                      } ${cx({
                        [classes.collapseItemMiniRTL]: rtlActive,
                      })}`;
                      return (
                        <ListItem key={key} className={classes.collapseItem}>
                          <NavLink to={prop.path} className={navLinkClasses}>
                            <span className={collapseItemMini}>
                              {prop.mini}
                            </span>
                            <ListItemText
                              primary={prop.name}
                              disableTypography
                              className={collapseItemText}
                            />
                          </NavLink>
                        </ListItem>
                      );
                    })}
                  </List>
                </Collapse>
              </ListItem>
            );
          }
          const navLinkClasses = `${classes.itemLink} ${cx({
            [` ${classes[color]}`]: this.activeRoute(prop.path),
          })}`;
          const itemText = `${classes.itemText} ${cx({
            [classes.itemTextMini]:
              this.props.miniActive && this.state.miniActive,
            [classes.itemTextMiniRTL]:
              rtlActive && this.props.miniActive && this.state.miniActive,
            [classes.itemTextRTL]: rtlActive,
          })}`;
          const itemIcon = `${classes.itemIcon} ${cx({
            [classes.itemIconRTL]: rtlActive,
          })}`;
          return (
            <ListItem key={key} className={classes.item}>
              <NavLink to={prop.path} className={navLinkClasses}>
                <ListItemIcon className={itemIcon}>
                  <prop.icon />
                </ListItemIcon>
                <ListItemText
                  primary={prop.name}
                  disableTypography
                  className={itemText}
                />
              </NavLink>
            </ListItem>
          );
        })}
      </List>
    );

    const logoNormal = `${classes.logoNormal} ${cx({
      [classes.logoNormalSidebarMini]:
        this.props.miniActive && this.state.miniActive,
      [classes.logoNormalSidebarMiniRTL]:
        rtlActive && this.props.miniActive && this.state.miniActive,
      [classes.logoNormalRTL]: rtlActive,
    })}`;
    const logoMini = `${classes.logoMini} ${cx({
      [classes.logoMiniRTL]: rtlActive,
    })}`;
    const logoClasses = `${classes.logo} ${cx({
      [classes.whiteAfter]: bgColor === 'white',
    })}`;
    const brand = (
      <div className={logoClasses}>
        <a href="" className={logoMini}>
          <img src={logo} alt="logo" className={classes.avatarImg} />
        </a>
        <a href="" className={logoNormal}>
          {logoText}
        </a>
      </div>
    );
    const drawerPaper = `${classes.drawerPaper} ${cx({
      [classes.drawerPaperMini]: this.props.miniActive && this.state.miniActive,
      [classes.drawerPaperRTL]: rtlActive,
    })}`;
    const sidebarWrapper = `${classes.sidebarWrapper} ${cx({
      [classes.drawerPaperMini]: this.props.miniActive && this.state.miniActive,
      [classes.sidebarWrapperWithPerfectScrollbar]:
        navigator.platform.indexOf('Win') > -1,
    })}`;
    return (
      <div ref="mainPanel">
        <Hidden mdUp>
          <Drawer
            variant="temporary"
            anchor={rtlActive ? 'left' : 'right'}
            open={this.props.open}
            classes={{
              paper: `${drawerPaper} ${classes[`${bgColor}Background`]}`,
            }}
            onClose={this.props.handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {brand}
            <SidebarWrapper
              className={sidebarWrapper}
              user={user}
              headerLinks={<HeaderLinks rtlActive={rtlActive} />}
              links={links}
            />
            {image !== undefined ? (
              <div
                className={classes.background}
                style={{ backgroundImage: `url(${image})` }}
              />
            ) : null}
          </Drawer>
        </Hidden>
        <Hidden smDown>
          <Drawer
            onMouseOver={() => this.setState({ miniActive: false })}
            onMouseOut={() => this.setState({ miniActive: true })}
            anchor={rtlActive ? 'right' : 'left'}
            variant="permanent"
            open
            classes={{
              paper: `${drawerPaper} ${classes[`${bgColor}Background`]}`,
            }}
          >
            {brand}
            <SidebarWrapper
              className={sidebarWrapper}
              user={user}
              links={links}
            />
            {image !== undefined ? (
              <div
                className={classes.background}
                style={{ backgroundImage: `url(${image})` }}
              />
            ) : null}
          </Drawer>
        </Hidden>
      </div>
    );
  }
}

Sidebar.defaultProps = {
  bgColor: 'blue',
};

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  bgColor: PropTypes.oneOf(['white', 'black', 'blue']),
  rtlActive: PropTypes.bool,
  color: PropTypes.oneOf([
    'white',
    'red',
    'orange',
    'green',
    'blue',
    'purple',
    'rose',
  ]),
  logo: PropTypes.string,
  logoText: PropTypes.string,
  image: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
};

const mapStateToProps = state => ({
  activeRole: state.main.activeRole,
  userDetails: state.main.userDetails,
  uid: state.main.uid,
});

export default connect(mapStateToProps)(withStyles(sidebarStyle)(Sidebar));
