const roleMapping = {
  'branch-ops': 'BranchOps',
  'transaction-view': 'TransactionView',
  'finance-payable': 'FinancePayable',
  'finance-recievable': 'FinanceReceivable',
  'finance-recon': 'FinanceRecon',
  'partner-exec-manager': 'PartnerExecManager',
  'tracking-role': 'TrackingRole',
};

const mapRole = activeRole => roleMapping[activeRole] || activeRole;

export default mapRole;
